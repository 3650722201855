import React, { useEffect, useState } from "react";
import {
  MapContainer,
  ImageOverlay,
  useMap,
  Rectangle,
  Marker,
  ZoomControl
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import MapSVG from "../../../images/warehouse/NAMI Maps_Full Site Layout.svg";
import gl01tl04 from "../../../images/warehouse/NAMI Maps_GL01 TL04.svg";
import tl01cl09 from "../../../images/warehouse/NAMI Maps_TL01 CL09.svg";
import fullSubAreaCP from "../../../images/warehouse/NAMI Maps 8 Beacon.svg";
import CPSubAreaOne from "../../../images/warehouse/NAMI Maps_CP Sub Area 1.svg";
import CPSubAreaTwo from "../../../images/warehouse/NAMI Maps_CP Sub Area 2.svg";
import CPUpstairsFull from "../../../images/warehouse/NAMI Maps_Full CP AREA  upstairs.svg";
import CPUpstairsSubArea from "../../../images/warehouse/NAMI Maps_CP Sub ARea Upstairs.svg";
import SecondFloor from "../../../images/warehouse/Plant Layout - Modjoul 2nd Floor.svg"
import L from "leaflet";
import { Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Popover, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { Stack, Chip, Icon } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import 'leaflet.heat'
import { CPSquares, CPMaintenanceSquares } from "../mapSquareCoordinates/CPSquaresData"
import Squares from "../mapSquareCoordinates/TL01CL09SquareData"
import CPMaintenanceSquares2ndFloor from "../mapSquareCoordinates/CPSquaresData2ndFloor";

interface MapProps {
  currentArea: string;
  setCurrentArea: Function;
  showAreaNames: boolean;
  showZones: boolean;
  setCurrentSubArea: Function;
  currentSubArea: string | null;
  heatMapEnabled: boolean
  selectedFloor: string
  setSelectedFloor: (floor:string) => void
}

// Function to calculate bounds from an array of heat points
const calculateBounds = (points: number[][]): L.LatLngBounds => {
  const latLngs = points.map((point) => L.latLng(point[0], point[1]));
  return L.latLngBounds(latLngs);
};

const heatPoints = [

  // CP82
  [51.4939, -0.0669, 0.9],
  [51.4939, -0.0672, 0.9],
  [51.4939, -0.0673, 0.9],
  [51.4939, -0.0662, 0.9],
  [51.4939, -0.0665, 0.9],
  
  // CP83
  [51.49393, -0.0659, 0.6],
  [51.4938, -0.0662, 0.9],
  [51.4938, -0.0657, 0.6],
  [51.4945, -0.0659, 0.6],
  [51.4938, -0.0654, 0.6],
  [51.4942, -0.0657, 0.3],
  [51.4938, -0.0657, 0.4],
  [51.4938, -0.0657, 0.4],
  [51.4944, -0.0657, 0.4],
  [51.4945, -0.0657, 0.3],

  // CP84
  [51.4939, -0.065, 0.9],
  [51.4939, -0.0655, 0.6],
  [51.4939, -0.0657, 0.9],
  [51.4939, -0.0659, 0.6],
  [51.4943, -0.065, 0.9],
  [51.4939, -0.0657, 0.9],
  [51.4943, -0.065, 0.9],
  [51.4943, -0.0657, 0.9],
  [51.4942, -0.0658, 0.9],
  [51.494, -0.0659, 0.9],


  // exit
  [51.4942, -0.065, 0.6],
  [51.4942, -0.0649, 0.6],
  [51.4942, -0.0646, 0.7],
  [51.4942, -0.0644, 0.5],
  [51.4942, -0.064, 0.6],
  [51.4942, -0.0638, 0.6],
  [51.4942, -0.0635, 0.5],
  [51.4942, -0.066, 0.9],
  [51.4942, -0.067, 0.9],
  [51.4942, -0.068, 0.9],
  [51.494, -0.065, 0.9],

];

// Adjust these coordinates to fit your specific SVG map
const bounds: L.LatLngBoundsExpression = [
  [51.49, -0.08], // Top left corner coordinates (latitude, longitude)
  [51.5, -0.06], // Bottom right corner coordinates (top, left corner)
];

const enum ImageOptions {
  FullSite = "fullSite",
  Gl01Tl04 = "gl01tl04",
  Tl01Cl09 = "tl01cl09",
  FullSubAreaCP = "fullSubAreaCP",
  CPSubAreaOne = "CPSubAreaOne",
  CPSubAreaTwo = "CPSubAreaTwo",
  CPUpstairsFull = "CPUpstairsFull",
  CPUpstairsSubArea = "CPUpstairsSubArea",
  SecondFloor = "2nd Floor",
}

const imageMap: Record<ImageOptions, string> = {
  [ImageOptions.FullSite]: MapSVG,
  [ImageOptions.Gl01Tl04]: gl01tl04,
  [ImageOptions.Tl01Cl09]: tl01cl09,
  [ImageOptions.FullSubAreaCP]: fullSubAreaCP,
  [ImageOptions.CPSubAreaOne]: CPSubAreaOne,
  [ImageOptions.CPSubAreaTwo]: CPSubAreaTwo,
  [ImageOptions.CPUpstairsFull]: CPUpstairsFull,
  [ImageOptions.CPUpstairsSubArea]: CPUpstairsSubArea,
  [ImageOptions.SecondFloor]: SecondFloor,
};

const getImage = (option: string): string => {
  return imageMap[option as ImageOptions];
};

const OverlayManager: React.FC<{
  currentArea: string;
  onMapLoad: (map: L.Map) => void;
  selectedFloor: string
}> = ({ currentArea, onMapLoad,selectedFloor }) => {
  const map = useMap();

  useEffect(() => {
    map.eachLayer((layer) => {
      if (layer instanceof L.ImageOverlay) {
        map.removeLayer(layer);
      }
    });

    // -------------------------------------------------------------------------------------------------------------------
    // Temporary logic to add the second floor map
    let url = ''
    if (selectedFloor === '2nd Floor') {
      url = SecondFloor
    } else if (selectedFloor ==='1st Floor') {
      url = getImage(currentArea);
    }
     else {
       url = getImage(currentArea);
    }

    // -------------------------------------------------------------------------------------------------------------------

    const overlay = new L.ImageOverlay(url, bounds);
    overlay.addTo(map);
    map.fitBounds(bounds);

    onMapLoad(map);

    return () => {
      map.removeLayer(overlay);
    };
  }, [currentArea, map, onMapLoad, selectedFloor]);

  return null;
};

const DashboardMap: React.FC<MapProps> = ({
  currentArea,
  showAreaNames,
  showZones,
  setCurrentSubArea,
  setCurrentArea,
  currentSubArea,
  heatMapEnabled,
  selectedFloor,
  setSelectedFloor
}) => {
  const [anchorPosition, setAnchorPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const [map, setMap] = useState<L.Map | null>(null);
  const [highlightedSquares, setHighlightedSquares] = useState<
    Record<string, boolean>
  >({});
  const [heatLayer, setHeatLayer] = useState<L.Layer | null>(null);

  const handleFloorChange = (event: SelectChangeEvent) => {
     // -------------------------------------------------------------------------------------------------------------------
    // Temporary logic to add the second floor map
    setSelectedFloor(event.target.value);
    if (event.target.value === '2nd Floor') {
      setCurrentArea('2ndFloor')
    }
    else {

      setCurrentArea('fullSite')
    }
    // -------------------------------------------------------------------------------------------------------------------
  }

  useEffect(() => {
    if (map && heatMapEnabled && !heatLayer) {
      // @ts-ignore
      const newHeatLayer = L.heatLayer(heatPoints, {
        radius: 25,
        blur: 15,
        maxZoom: 17,
      }).addTo(map);
      setHeatLayer(newHeatLayer); 
      const bounds = calculateBounds(heatPoints);
      map.fitBounds(bounds);
    }

    if (map && !heatMapEnabled && heatLayer) {
      map.removeLayer(heatLayer);
      setHeatLayer(null);
    }
  }, [map, heatMapEnabled, heatLayer]);

  const handleRectangleClick = (id: string) => {
    setCurrentSubArea(id); 
  };

  const handleRectangleClose = () => {
    setAnchorPosition(null);
  };

  const handleMouseOver = (id: string) => {
    setHighlightedSquares((prev) => ({ ...prev, [id]: true }));
  };

  const handleMouseOut = (id: string) => {
    setHighlightedSquares((prev) => ({ ...prev, [id]: false }));
  };

  const open = Boolean(anchorPosition);
  const id = open ? "simple-popover" : undefined;

  const InitialViewSetter = () => {
    const map = useMap();
    useEffect(() => {
      if (currentArea === "fullSite") {
        map.setView([51.495, -0.07], 16.5);
      } else {
        map.setView([51.495, -0.07], 16.6);
      }
    }, [map]);
    return null;
  };

  

  const fullSiteSquareBoundsOne: L.LatLngBoundsExpression = [
    [51.4936, -0.066], // Bottom left corner coordinates (bottom, right corner)
    [51.49455, -0.06935], // Top left corner coordinates (top, left corner)
  ];
  const fullSiteSquareBoundsTwo: L.LatLngBoundsExpression = [
    [51.49592, -0.06421], // Bottom left corner coordinates (bottom, right corner)
    [51.49659, -0.0669], // Top left corner coordinates (top, left corner)
  ];
  const fullSiteSquareBoundsThree: L.LatLngBoundsExpression = [
    [51.49579, -0.06405], // Bottom left corner coordinates (bottom, right corner)
    [51.49659, -0.06215], // Top left corner coordinates (top, left corner)
  ];

  const fullSiteSquares = [
    { id: "fullSubAreaCP", bounds: fullSiteSquareBoundsOne },
    { id: "gl01tl04", bounds: fullSiteSquareBoundsTwo },
    { id: "tl01cl09", bounds: fullSiteSquareBoundsThree },
  ];

  return (
    <div style={{ position: "relative", padding:'0'}}>
      <MapContainer
        className="custom-map-container"
        style={{ height: "80vh", width: "90vw", backgroundColor: "white" }}
        zoomSnap={0.00001}
        zoomDelta={0.00001}
        zoomControl={false}
      >
        <InitialViewSetter />
        <ZoomControl position="topright" />
        <OverlayManager currentArea={currentArea} onMapLoad={setMap} selectedFloor={selectedFloor} />

        <Box
          sx={{
            position: "absolute",
            top: 10, // Adjust to match the zoom control's top position
            right: 60, // Adjust right positioning to appear next to zoom buttons
            backgroundColor: "white",
            padding: "8px",
            zIndex: 1000, // Ensure it is above the map
            display: 'flex',
            alignItems: 'center',
            borderRadius: '4px',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)'
          }}
        >
 <FormControl sx={{ minWidth: 120, marginRight: '3%' }}>
  <InputLabel id="floor-select-label">Floor</InputLabel>
  <Select
    labelId="floor-select-label"
    id="floor-select"
    value={selectedFloor}
    onChange={handleFloorChange}
    input={<OutlinedInput label="Floor" />} // Adding OutlinedInput for consistency
  >
    <MenuItem value="1st Floor">1st Floor</MenuItem>
    <MenuItem value="2nd Floor">2nd Floor</MenuItem>
  </Select>
</FormControl>
        </Box>
        {showZones &&
          currentArea === "2ndFloor" &&
          CPMaintenanceSquares2ndFloor.map((square) => (
            <Rectangle
              key={square.id}
              bounds={square.bounds}
              pathOptions={{
                color: "red",
                weight: 0,
              }}
              eventHandlers={{
                mousedown: () => setCurrentSubArea(square.id),
              }}
            />
          ))}
        {showZones &&
          currentArea === "tl01cl09" &&
          Squares.map((square) => (
            <Rectangle
              key={square.id}
              bounds={square.bounds}
              pathOptions={{
                color: "green",
                weight: 0,
              }}
              eventHandlers={{
                mousedown: () => setCurrentSubArea(square.id),
              }}
            />
          ))}

        {showZones &&
          currentArea === "fullSite" &&
          fullSiteSquares.map((square) => (
            <Rectangle
              key={square.id}
              bounds={square.bounds}
              pathOptions={{
                color: "orange",
                weight: 0,
              }}
              eventHandlers={{
                mousedown: () => setCurrentArea(square.id),
              }}
            />
          ))}
          
          {showZones &&
  currentArea === "fullSubAreaCP" &&
  CPSquares.map((square, index) => (
    <React.Fragment key={square.id}>
      <Rectangle
        bounds={square.bounds}
        pathOptions={{
          color: currentSubArea === square.id ? "rgba(255, 255, 0, 1)" : "rgba(0, 255, 0, 0.5)", 
          weight: 0,
        }}
        eventHandlers={{
          click: () => handleRectangleClick(square.id)
        }}
      />
      
    </React.Fragment>
  ))}

{showZones &&
  currentArea === "fullSubAreaCP" &&
  CPMaintenanceSquares.map((square, index) => (
    <React.Fragment key={square.id}>
      <Rectangle
        bounds={square.bounds}
        pathOptions={{
          color: currentSubArea === square.id ? "rgba(255, 255, 0, 1)" : "rgba(255, 0, 0, 0.5)", 
          weight: 0,
          fillOpacity:.6,
        }}
        eventHandlers={{
          click: () => handleRectangleClick(square.id)
        }}
      />
      
    </React.Fragment>
  ))}

  {showAreaNames && currentArea === 'fullSubAreaCP' &&
   CPSquares.map((square, index) => (
    <React.Fragment key={square.id}>
    
      <Marker
        position={[
          index < 12
            ? ((square.bounds[0][0] + square.bounds[1][0]) / 2) + 0.0010 // First row of machines
            : ((square.bounds[0][0] + square.bounds[1][0]) / 2) - 0.0011, // Second row of machines
          (square.bounds[0][1] + square.bounds[1][1]) / 2, 
        ]}
        icon={L.divIcon({
          className: "custom-div-icon",
          html: `<div style="color: black; font-size: 12px; font-weight: bold;">${square.id}</div>`,
        })}
      />
    </React.Fragment>
  ))}
        {showAreaNames && currentArea === "fullSite" && (
          <>
            <Marker
              position={[51.4958, -0.0657]}
              icon={L.divIcon({
                className: "custom-div-icon",
                html: '<div style="color: red; font-size: 24px; font-weight: bold;">Gl01</div>',
              })}
            /> 
            <Marker
              position={[51.4969, -0.0657]}
              icon={L.divIcon({
                className: "custom-div-icon",
                html: '<div style="color: red; font-size: 24px; font-weight: bold;">TL04</div>',
              })}
            />
            <Marker
              position={[51.4949, -0.068]} 
              icon={L.divIcon({
                className: "custom-div-icon",
                html: '<div style="color: red; font-size: 24px; font-weight: bold;">CP0136</div>',
              })}
            />
            <Marker
              position={[51.4935, -0.068]} 
              icon={L.divIcon({
                className: "custom-div-icon",
                html: '<div style="color: red; font-size: 24px; font-weight: bold;">CP6184</div>',
              })}
            />
            <Marker
              position={[51.4969, -0.0634]} 
              icon={L.divIcon({
                className: "custom-div-icon",
                html: '<div style="color: red; font-size: 24px; font-weight: bold;">CL09</div>',
              })}
            />
            <Marker
              position={[51.4957, -0.0634]} 
              icon={L.divIcon({
                className: "custom-div-icon",
                html: '<div style="color: red; font-size: 24px; font-weight: bold;">TL01</div>',
              })}
            />
          </>
        )}

        {showAreaNames && currentArea === "tl01cl09" && (
          <>
            <Marker
              position={[51.4973, -0.0705]}
              icon={L.divIcon({
                className: "custom-div-icon",
                html: '<div style="color: red; font-size: 24px; font-weight: bold;">CL09</div>',
              })}
            />
            <Marker
              position={[51.4925, -0.0705]}
              icon={L.divIcon({
                className: "custom-div-icon",
                html: '<div style="color: red; font-size: 24px; font-weight: bold;">TL01</div>',
              })}
            />
          </>
        )}
      </MapContainer>
      <Popover
        id={id}
        open={open}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition || { top: 0, left: 0 }}
        onClose={handleRectangleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <CardContent>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: 2 }}
            >
              <CircleIcon sx={{ color: "green", paddingRight: "6px" }} />
              <Typography variant="h5" component="div">
                Maintenance
              </Typography>
            </Stack>
          </Stack>
          <Typography variant="body1" color="text.secondary">
            Conveyor #325 has <strong>severe</strong> vibration{" "}
            <strong>Code A.35.1</strong>.
          </Typography>
        </CardContent>
      </Popover>
    </div>
  );
};

export default DashboardMap;
