import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Box,
  Typography,
} from "@mui/material";

// Define the data type
interface DataRow {
  role: string;
  avgTemp: number;
  avgDecibel: number;
  avgHumidity: number;
  tempThresholds: number;
  decibelThresholds: number;
}

const data: DataRow[] = [
  {
    role: "Job Role 15",
    avgTemp: 93,
    avgDecibel: 86,
    avgHumidity: 65,
    tempThresholds: 5,
    decibelThresholds: 1,
  },
  {
    role: "Job Role 13",
    avgTemp: 92,
    avgDecibel: 79,
    avgHumidity: 65,
    tempThresholds: 1,
    decibelThresholds: 0,
  },
  {
    role: "Job Role 3",
    avgTemp: 90,
    avgDecibel: 89,
    avgHumidity: 56,
    tempThresholds: 0,
    decibelThresholds: 5,
  },
  {
    role: "Job Role 5",
    avgTemp: 89,
    avgDecibel: 91,
    avgHumidity: 60,
    tempThresholds: 8,
    decibelThresholds: 6,
  },
  {
    role: "Job Role 17",
    avgTemp: 89,
    avgDecibel: 87,
    avgHumidity: 65,
    tempThresholds: 5,
    decibelThresholds: 5,
  },
  {
    role: "Job Role 22",
    avgTemp: 89,
    avgDecibel: 83,
    avgHumidity: 61,
    tempThresholds: 2,
    decibelThresholds: 3,
  },
  {
    role: "Job Role 7",
    avgTemp: 89,
    avgDecibel: 80,
    avgHumidity: 56,
    tempThresholds: 1,
    decibelThresholds: 2,
  },
  {
    role: "Job Role 6",
    avgTemp: 88,
    avgDecibel: 84,
    avgHumidity: 56,
    tempThresholds: 5,
    decibelThresholds: 3,
  },
  {
    role: "Job Role 9",
    avgTemp: 88,
    avgDecibel: 58,
    avgHumidity: 58,
    tempThresholds: 1,
    decibelThresholds: 6,
  },
  {
    role: "Job Role 11",
    avgTemp: 87,
    avgDecibel: 84,
    avgHumidity: 59,
    tempThresholds: 4,
    decibelThresholds: 6,
  },
  {
    role: "Job Role 14",
    avgTemp: 87,
    avgDecibel: 91,
    avgHumidity: 64,
    tempThresholds: 6,
    decibelThresholds: 4,
  },
];

// const getColor = (value: number): string => {
//   if (value < 40) return "#FFCCCB"; // Light red
//   if (value < 60) return "#FFFFE0"; // Light yellow
//   return "#FFFACD"; // Light gold
// };

// const getColor = (value: number): string => {
//   if (value > 90) return "rgba(92, 230, 92, 0.7)"; // Light green
//   // if (value > 90) return "rgba(144, 238, 144, 0.6)"; // Light green 2
//   if (value > 85) return "rgba(173, 255, 47, 0.6)"; // Light green-yellow
//   if (value > 80) return "rgba(255, 255, 224, 0.6)"; // Light yellow
//   // if (value > 75) return "rgba(255, 239, 213, 0.6)"; // Peach
//   if (value > 70) return "rgba(255, 204, 203, 0.6)"; // Light red
//   // if (value > 65) return "rgba(255, 182, 193, 0.6)"; // Light pink
//   if (value > 60) return "rgba(255, 165, 0, 0.6)"; // Orange
//   if (value > 55) return "rgba(255, 69, 0, 0.8)"; // Red-orange
//   if (value > 50) return "rgba(255, 0, 0, 0.6)"; // Red
//   return "rgba(223, 240, 216, 0.6)"; // Light green with transparency
// };

const getColorForColumn = (column: keyof DataRow, value: number): string => {
  switch (column) {
    case "avgTemp":
      if (value >= 91) return "#ed7668"; // Dark red
      if (value >= 89) return "#f2b476"; // Light orange
      if (value >= 87) return "#eedc6b"; // Light yellow
      if (value >= 85) return "#e5e579"; // Yellow-green
      if (value >= 80) return "#9cc593"; // Light green
      return "#f2f2f2"; // Default light gray

    case "avgDecibel":
      if (value >= 90) return "#ed7668"; // Dark red
      if (value >= 85) return "#f2b476"; // Light orange
      if (value >= 80) return "#eedc6b"; // Light yellow
      // if (value >= 75) return "#e5e579"; // Yellow-green
      if (value >= 70) return "#9cc593"; // Light green
      return "#f2f2f2"; // Default light gray

    case "avgHumidity":
      if (value >= 65) return "#ed7668"; // Dark red
      if (value >= 60) return "#f2b476"; // Light orange
      if (value >= 55) return "#eedc6b"; // Light yellow
      if (value >= 50) return "#9cc593"; // Light green
      return "#9cc593"; // Light green

    case "tempThresholds":
      if (value >= 8) return "#ed7668"; // Dark red
      if (value >= 6) return "#f2b476"; // Light orange
      if (value >= 4) return "#eedc6b"; // Light yellow
      if (value >= 2) return "#e5e579"; // Yellow-green
      return "#9cc593"; // Light green

    case "decibelThresholds":
      if (value >= 8) return "#ed7668"; // Dark red
      if (value >= 6) return "#f2b476"; // Light orange
      if (value >= 5) return "#eedc6b"; // Light yellow
      if (value >= 2) return "#e5e579"; // Yellow-green
      return "#9cc593"; // Light green

    default:
      return "#f2f2f2"; // Default light gray
  }
};

type Order = "asc" | "desc";

const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = <T,>(
  order: Order,
  orderBy: keyof T
): ((a: T, b: T) => number) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = <T,>(
  array: T[],
  comparator: (a: T, b: T) => number
): T[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

interface TableTwoProps {
  highlighting: boolean;
  currentArea: string;
  currentSubArea: string | null;
}

function TableTwo({
  highlighting,
  currentArea,
  currentSubArea,
}: TableTwoProps) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof DataRow>("role");

  const handleRequestSort = (property: keyof DataRow) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = stableSort(data, getComparator(order, orderBy));

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontWeight: "bold" }}>
          Employee Environmental {currentArea}{" "}
          {currentArea && currentArea != "fullSite" && currentSubArea}
        </Typography>
      </Box>
      <TableContainer
        component={Paper}
        style={{ maxHeight: 325, overflow: "auto" }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {[
                { id: "role", label: "" },
                { id: "avgTemp", label: "AVG Temp" },
                { id: "avgDecibel", label: "AVG Decibel" },
                { id: "avgHumidity", label: "AVG Humidity" },
                { id: "tempThresholds", label: "Temp Thresholds" },
                { id: "decibelThresholds", label: "Decibel Thresholds" },
              ].map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align="right"
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={{
                    padding: "2px 8px",
                    // whiteSpace: "nowrap",
                    overflow: "hidden",
                    fontWeight: "bold",
                    textOverflow: "ellipsis",
                  }} // Reduced padding and nowrap
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={() =>
                      handleRequestSort(headCell.id as keyof DataRow)
                    }
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, index) => (
              <TableRow key={index} sx={{ height: 24 }}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    padding: "2px 8px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: "bold",
                  }} // Reduced padding and nowrap
                >
                  {row.role}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn("avgTemp", row.avgTemp)
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                  }}
                >
                  {row.avgTemp}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn("avgDecibel", row.avgDecibel)
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                  }}
                >
                  {row.avgDecibel}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn("avgHumidity", row.avgHumidity)
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                  }}
                >
                  {row.avgHumidity}%
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn("tempThresholds", row.tempThresholds)
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                  }}
                >
                  {row.tempThresholds}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn(
                          "decibelThresholds",
                          row.decibelThresholds
                        )
                      : undefined,
                    padding: "5px 8px", // Reduced padding
                    fontWeight: "bold",
                  }}
                >
                  {row.decibelThresholds}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default TableTwo;
