import { Box, Button, Card, CardContent, Stack, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {useState} from 'react'


interface NotificationCardData {
    date: string,
    info: string,
    alertColor: string
}

interface NotificationCardProps {
card : NotificationCardData
numberOfCards : number
setNumberOfCards: Function
}

function NotificationCard({card, numberOfCards, setNumberOfCards}:NotificationCardProps) {

    const [isDsiplayed, setIsDisplayed] = useState(true)


    const handleXClick = () => {
        setIsDisplayed(false)
        setNumberOfCards(numberOfCards - 1)
    }
  return (
    
    isDsiplayed ? (
    <Card sx={{ m: 2, position: 'relative', paddingLeft: '16px' }}>  
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          width: '8px',
          backgroundColor: card.alertColor,
        }}
      />
      <CardContent>
        <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
            <Typography variant="h5" component="div">
              Monday, 2:05pm, 06/01/24
              {card.date}
            </Typography>
          </Stack>
          <Stack>
            <Button onClick={handleXClick}>
              <CloseIcon />
            </Button>
          </Stack>
        </Stack>
        <Typography variant="body1" color="text.secondary">
          Temperature Threshold Cure Press Area.
          {card.info}
        </Typography>

        <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
          <Stack direction="row" spacing={1} alignItems="center"></Stack>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button size="small">
              <Typography style={{ textDecoration: 'underline', fontWeight: 'bold' }} variant="body1" color="text.secondary">
                View
              </Typography>
            </Button>
            <Button size="small">
              <Typography style={{ textDecoration: 'underline', fontWeight: 'bold' }} variant="body1" color="text.secondary">
                Archive
              </Typography>
            </Button>
          </Box>
        </Stack>
      </CardContent>
    </Card>
    
    


  ) : null)
}

export default NotificationCard;
