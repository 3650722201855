import React from "react";
import { List, ListItem, ListItemIcon, ListItemText, Box, ListItemButton } from "@mui/material";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import MapIcon from "@mui/icons-material/Map";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Link } from "react-router-dom";

interface LeftBarProps {
  currentPage: string;
  setCurrentPage: Function;
  isAuth: boolean | undefined;
}

const iconMapping: { [key: string]: React.ReactNode } = {
  Map: <MapIcon />,
  Dashboard: <DashboardIcon />,
  Notifications: <ManageSearchIcon />,
  // "General Manager": <GroupIcon />,
};

function LeftBar({ currentPage, setCurrentPage, isAuth }: LeftBarProps) {
  if (!isAuth) {
    return null;
  }

  return (
    <Box sx={{borderRight: "1px solid #ccc"}}  >
    <List>
      <Link to="/map" style={{ textDecoration: 'none', color: 'inherit' }}>
      <ListItemButton >
        <Box sx={{display:'flex', flexDirection:'column',  width:'100%'}}>
      <ListItemIcon sx={{justifyContent:'center'}} >
            {iconMapping['Map']} 
          </ListItemIcon>
        <ListItem sx={{justifyContent:'center', fontFamily:'Helvetica'}}>Map</ListItem>
        </Box>
      </ListItemButton>
      </Link>
      <Link to="/dashboard" style={{ textDecoration: 'none', color: 'inherit' }}>
      <ListItemButton >
      <Box sx={{display:'flex', flexDirection:'column'}}>
      <ListItemIcon sx={{justifyContent:'center'}} >
            {iconMapping['Dashboard']} 
          </ListItemIcon>
        <ListItem sx={{justifyContent:'center',  fontFamily:'Helvetica'}}>Dashboard</ListItem>
      </Box>
      </ListItemButton>
      </Link>
      
    </List>
  </Box>

      // <Box sx={{ width: "7%" }} position="fixed">
      //   <List>
      //     {/* {["Map", "Dashboard", "Notifications", "TestPage"].map((text, index) =>  */}
      //     {["Map", "Dashboard"].map((text, index) => 
          
      //     (
      //       <Link
      //         to={text.toLowerCase()}
      //         style={{ textDecoration: "none", color: "inherit" }}
      //       >
      //       <ListItemButton>

            
      //         <ListItem
      //           button
      //           key={text}
      //           sx={{
      //             display: "flex",
      //             flexDirection: "column",
      //           }}
      //           onClick={() => {
      //             setCurrentPage(text);
      //           }}
      //         >
      //           <ListItemIcon sx={{ marginLeft: "31px" }}>
      //             {iconMapping[text]}
      //           </ListItemIcon>
      //           <ListItemText
      //             sx={{
      //               width: "100%",
      //               textAlign: "center",
      //             }}
      //             primary={text}
      //           />
      //         </ListItem>
      //       </ListItemButton>
      //       </Link>
      //     ))}
      //   </List>
      // </Box>
  );
}

export default LeftBar;
