import { LatLngBoundsExpression } from "leaflet";

type LatLngTuple = [number, number];
interface CPSquare {
  id: string;
  bounds: [LatLngTuple, LatLngTuple]; 
}

interface CPMaintenanceSquare {
  id: string;
  bounds: [LatLngTuple, LatLngTuple]
}

const CPSquares: CPSquare[] = [
  { id: "CP25", bounds: [[51.49515, -0.07535],  // Bottom left corner coordinates (bottom, right corner)
                        [51.494425, -0.0762]]   // Top right corner coordinates (top, left corner)
                      }, 
  { id: "CP26", bounds: [[51.49515, -0.07438], [51.494425, -0.07523]] },
  { id: "CP27", bounds: [[51.49515, -0.07341], [51.494425, -0.07426]] },
  { id: "CP28", bounds: [[51.49515, -0.07244], [51.494425, -0.07329]] },
  { id: "CP29", bounds: [[51.49515, -0.07147], [51.494425, -0.07232]] },
  { id: "CP30", bounds: [[51.49515, -0.07050], [51.494425, -0.07135]] },
  { id: "CP31", bounds: [[51.49515, -0.06953], [51.494425, -0.07038]] },
  { id: "CP32", bounds: [[51.49515, -0.06856], [51.494425, -0.06941]] },
  { id: "CP33", bounds: [[51.49515, -0.06759], [51.494425, -0.06844]] },
  { id: "CP34", bounds: [[51.49515, -0.06662], [51.494425, -0.06747]] },
  { id: "CP35", bounds: [[51.49515, -0.06565], [51.494425, -0.06650]] },
  { id: "CP36", bounds: [[51.49515, -0.06468], [51.494425, -0.06553]] },
  { id: "CP73", bounds: [[51.494, -0.07531], [51.49335, -0.07615]] },
  { id: "CP74", bounds: [[51.494, -0.07425], [51.49335, -0.07522]] },
  { id: "CP75", bounds: [[51.494, -0.07332], [51.49335, -0.07417]] },
  { id: "CP76", bounds: [[51.494, -0.07238], [51.49335, -0.07323]] },
  { id: "CP77", bounds: [[51.494, -0.07144], [51.49335, -0.07229]] },
  { id: "CP78", bounds: [[51.494, -0.0705], [51.49335, -0.07135]] },
  { id: "CP79", bounds: [[51.494, -0.06953], [51.49335, -0.07041]] },
  { id: "CP80", bounds: [[51.494, -0.06856], [51.49335, -0.06944]] },
  { id: "CP81", bounds: [[51.494, -0.0676], [51.49335, -0.06848]] },
  { id: "CP82", bounds: [[51.494, -0.06663], [51.49335, -0.06751]] },
  { id: "CP83", bounds: [[51.494, -0.06567], [51.49335, -0.06654]] },
  { id: "CP84", bounds: [[51.494, -0.0647], [51.49335, -0.06558]] },
  { id: "", bounds: [[51.493276, -0.0647], [51.49263, -0.06558]] }, //CP84 Maint
  { id: "", bounds: [[51.493276, -0.06566], [51.49263, -0.06653]] }, //CP83 Maint
  { id: "", bounds: [[51.493276, -0.06662], [51.49263, -0.06748]] }, //CP82 Maint
  { id: "", bounds: [[51.493276, -0.06758], [51.49263, -0.06843]] }, //CP81 Maint
  { id: "", bounds: [[51.493276, -0.06854], [51.49263, -0.06938]] }, //CP80 Maint
  { id: "", bounds: [[51.493276, -0.0695], [51.49263, -0.07033]] }, //CP79 Maint
  { id: "", bounds: [[51.493276, -0.07044], [51.49263, -0.07128]] }, //CP78 Maint
  { id: "", bounds: [[51.493276, -0.0714], [51.49263, -0.07223]] }, //CP77 Maint
  { id: "", bounds: [[51.493276, -0.07236], [51.49263, -0.07318]] }, //CP76 Maint
  { id: "", bounds: [[51.493276, -0.07332], [51.49263, -0.07413]] }, //CP75 Maint
  { id: "", bounds: [[51.493276, -0.07428], [51.49263, -0.0751]] }, //CP74 Maint
  { id: "", bounds: [[51.493276, -0.07524], [51.49263, -0.07615]] }, //CP73 Maint
  
  { id: "", bounds: [[51.49565, -0.07532], [51.4952, -0.07622]] }, //CP25 Maint
  { id: "", bounds: [[51.49565, -0.07438], [51.4952, -0.07526]] }, //CP26 Maint
  { id: "", bounds: [[51.49565, -0.0734], [51.4952, -0.0743]] }, //CP27 Maint
  { id: "", bounds: [[51.49565, -0.07242], [51.4952, -0.07332]] }, //CP28 Maint
  { id: "", bounds: [[51.49565, -0.07147], [51.4952, -0.07235]] }, //CP29 Maint
  { id: "", bounds: [[51.49565, -0.0705], [51.4952, -0.07138]] }, //CP30 Maint
  { id: "", bounds: [[51.49565, -0.06952], [51.4952, -0.07042]] }, //CP31 Maint
  { id: "", bounds: [[51.49565, -0.06854], [51.4952, -0.06946]] }, //CP32 Maint
  { id: "", bounds: [[51.49565, -0.06758], [51.4952, -0.0685]] }, //CP33 Maint
  { id: "", bounds: [[51.49565, -0.06664], [51.4952, -0.06751]] }, //CP34 Maint
  { id: "", bounds: [[51.49565, -0.06566], [51.4952, -0.06655]] }, //CP35 Maint
  { id: "", bounds: [[51.49565, -0.06468], [51.4952, -0.06559]] }, //CP36 Maint
];

const CPMaintenanceSquares: CPMaintenanceSquare[] = [
  // Clock-wise
  // Bottom row to top row
  { id: "CP84Maint", bounds: [[51.493276, -0.0647], [51.49263, -0.06558]] }, 
  { id: "CP83Maint", bounds: [[51.493276, -0.06566], [51.49263, -0.06653]] }, 
  { id: "CP82Maint", bounds: [[51.493276, -0.06662], [51.49263, -0.06748]] }, 
  { id: "CP81Maint", bounds: [[51.493276, -0.06758], [51.49263, -0.06843]] }, 
  { id: "CP80Maint", bounds: [[51.493276, -0.06854], [51.49263, -0.06938]] }, 
  { id: "CP79Maint", bounds: [[51.493276, -0.0695], [51.49263, -0.07033]] }, 
  { id: "CP78Maint", bounds: [[51.493276, -0.07044], [51.49263, -0.07128]] }, 
  { id: "CP77Maint", bounds: [[51.493276, -0.0714], [51.49263, -0.07223]] }, 
  { id: "CP76Maint", bounds: [[51.493276, -0.07236], [51.49263, -0.07318]] }, 
  { id: "CP75Maint", bounds: [[51.493276, -0.07332], [51.49263, -0.07413]] }, 
  { id: "CP74Maint", bounds: [[51.493276, -0.07428], [51.49263, -0.0751]] }, 
  { id: "CP73Maint", bounds: [[51.493276, -0.07524], [51.49263, -0.07615]] }, 
  
  { id: "CP25Maint", bounds: [[51.49565, -0.07532], [51.4952, -0.07622]] }, 
  { id: "CP26Maint", bounds: [[51.49565, -0.07438], [51.4952, -0.07526]] }, 
  { id: "CP27Maint", bounds: [[51.49565, -0.0734], [51.4952, -0.0743]] }, 
  { id: "CP28Maint", bounds: [[51.49565, -0.07242], [51.4952, -0.07332]] }, 
  { id: "CP29Maint", bounds: [[51.49565, -0.07147], [51.4952, -0.07235]] }, 
  { id: "CP30Maint", bounds: [[51.49565, -0.0705], [51.4952, -0.07138]] }, 
  { id: "CP31Maint", bounds: [[51.49565, -0.06952], [51.4952, -0.07042]] }, 
  { id: "CP32Maint", bounds: [[51.49565, -0.06854], [51.4952, -0.06946]] }, 
  { id: "CP33Maint", bounds: [[51.49565, -0.06758], [51.4952, -0.0685]] }, 
  { id: "CP34Maint", bounds: [[51.49565, -0.06664], [51.4952, -0.06751]] }, 
  { id: "CP35Maint", bounds: [[51.49565, -0.06566], [51.4952, -0.06655]] }, 
  { id: "CP36Maint", bounds: [[51.49565, -0.06468], [51.4952, -0.06559]] }, 
]

export {CPSquares, CPMaintenanceSquares};
