import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Box,
  Typography,
  CircularProgress
} from "@mui/material";
import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL;

// Define the data type
interface DataRow {
  zone: string;
  percentageTimeSpent: number;
}

interface TableOneProps {
  highlighting: boolean;
  dateRange: [Date | undefined, Date | undefined];
  currentArea: string;
  currentSubArea: string | null;

}

const getColorForColumn = (column: keyof DataRow, value: number): string => {
  switch (column) {
    case "percentageTimeSpent":
      if (value >= 20) return "#0c5720";   // Dark green for the highest values (20%+)
      if (value >= 15) return "#47a02f";   // Strong green (15-19%)
      if (value >= 10) return "#75d62b";   // Medium green (10-14%)
      if (value >= 8)  return "#99c93e";   // Light green (8-9%)
      if (value >= 6)  return "#b0c93e";   // Green-yellow (6-7%)
      if (value >= 4)  return "#cbd934";   // Yellow (4-5%)
      if (value >= 2)  return "#d99134";   // Orange (2-3%)
      if (value >= 1)  return "#d96b34";   // Dark orange (1-1.9%)
      if (value >= 0.5) return "#d94434";  // Tomato (0.5-0.9%)
      return "#f2f2f2"; // Default light gray

    default:
      return "#f2f2f2"; // Default light gray
  }
};

type Order = "asc" | "desc";

const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = <T,>(
  order: Order,
  orderBy: keyof T
): ((a: T, b: T) => number) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = <T,>(
  array: T[],
  comparator: (a: T, b: T) => number
): T[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

function TableOne({
  highlighting,
  dateRange,
  currentArea,
  currentSubArea,
}: TableOneProps) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof DataRow>("zone");
  const [data, setData]=useState<DataRow[]|null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!dateRange[0] || !dateRange[1]) {
      console.log("Please select a date range");
      return;
    }
    
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await axios.get(`${apiUrl}/api/zone-usage`, 
        // const response = await axios.get('http://localhost:4000/api/top-row-zones-usage', 
          
          {
          params: {
            startDate: dateRange[0]?.toISOString(),  // Convert Date object to ISO string
            endDate: dateRange[1]?.toISOString()    // Convert Date object to ISO string
          }
        });
        console.log('Top Row Data received:', response.data);
        let dataset = response.data.slice(0,5)
        setData(dataset)
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  },[dateRange])

  const handleRequestSort = (property: keyof DataRow) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = data ? stableSort(data, getComparator(order, orderBy)) : [];
  return (
    <>
      <TableContainer component={Paper} style={{ maxHeight: 325, overflow: "auto" }}>
        <Typography
          sx={{
            fontWeight: "bold",
            paddingTop: "1%",
            fontSize: "15px",
            marginLeft: "2%",
          }}
        >
          Top Five Zones by Usage {currentArea}{" "}
          {currentArea && currentArea !== "fullSite" && currentSubArea}
        </Typography>
        {loading ? (
          // Display the loading spinner when loading state is true
          <Box sx={{ display: "flex", justifyContent: "center", padding: "20px" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {[
                  { id: "role", label: "Zone" },
                  { id: "percentageTimeSpent", label: "Time In Zone %" },
                ].map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align="right"
                    sortDirection={orderBy === headCell.id ? order : false}
                    sx={{
                      padding: "2px 8px",
                      overflow: "hidden",
                      fontWeight: "bold",
                      textOverflow: "ellipsis",
                      zIndex: "0",
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleRequestSort(headCell.id as keyof DataRow)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((row, index) => (
                <TableRow key={index} sx={{ height: 24 }}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      padding: "2px 8px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontWeight: "bold",
                    }}
                  >
                    {row.zone}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      backgroundColor: highlighting
                        ? getColorForColumn("percentageTimeSpent", row.percentageTimeSpent)
                        : undefined,
                      padding: "2px 8px",
                      fontWeight: "bold",
                    }}
                  >
                    {row.percentageTimeSpent}%
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  );
}

export default TableOne;
