import { useState } from 'react';
import axios from 'axios';
import DateRangePickerComponent from '../components/mapPage/components/persistentDrawerRight/rightBarSubItems/DateRangePickerComponent';

type DataPoint = {
    warehouseArea: string;
    deviceId: string;
    timestamp: string;
    xPosition: number;
    yPosition: number;
    zone: string;
  };

    // Helper function to render each data point
    const renderDataPoint = (dataPoint:any, index:number) => {
        return (
          <div key={index}>
            <p><strong>Device ID:</strong> {dataPoint.deviceId}</p>
            <p><strong>Timestamp:</strong> {new Date(dataPoint.timestamp).toLocaleString()}</p>
            <p><strong>X Position:</strong> {dataPoint.xPosition}</p>
            <p><strong>Y Position:</strong> {dataPoint.yPosition}</p>
            <p><strong>Zone:</strong> {dataPoint.zone}</p>
            <p><strong>Warehouse Area:</strong> {dataPoint.warehouseArea}</p>
            <hr />
          </div>
        );
      };

function TestPage() {
  const [dateRange, setDateRange] = useState<[Date | undefined, Date | undefined]>([undefined, undefined]);
  const [currentDataSet, setCurrentDataSet] = useState<DataPoint[]>([])

  const handleFetchData = async () => {
    if (!dateRange[0] || !dateRange[1]) {
      console.log("Please select a date range");
      return;
    }

    try {
      const response = await axios.post('http://18.232.163.40:4000/readings-by-date', {
        startDate: dateRange[0],
        endDate: dateRange[1]
      });
      console.log('Data received:', response.data);
      setCurrentDataSet(response.data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div>
      <DateRangePickerComponent dateRange={dateRange} setDateRange={setDateRange} />
      <button onClick={handleFetchData}>Fetch Data</button>

      {/* Display the current dataset */}
      {currentDataSet.length > 0 ? (
        <div>
          {currentDataSet.map((dataPoint, index) => renderDataPoint(dataPoint, index))}
        </div>
      ) : (
        <p>No data fetched yet.</p>
      )}
    </div>
  );
}

export default TestPage;
