import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Box,
  Typography,
} from "@mui/material";

// Define the data type
interface DataRow {
  role: string;
  lumbarScore: number;
  bendsPerHour: number;
  bendsOver60PerHour: number;
  twistsPerHour: number;
}

// Sample data from the image
const data: DataRow[] = [
  {
    role: "Job Role 9",
    lumbarScore: 480,
    bendsPerHour: 91,
    bendsOver60PerHour: 14,
    twistsPerHour: 44,
  },
  {
    role: "Job Role 4",
    lumbarScore: 435,
    bendsPerHour: 84,
    bendsOver60PerHour: 54,
    twistsPerHour: 16,
  },
  {
    role: "Job Role 22",
    lumbarScore: 397,
    bendsPerHour: 76,
    bendsOver60PerHour: 17,
    twistsPerHour: 12,
  },
  {
    role: "Job Role 14",
    lumbarScore: 387,
    bendsPerHour: 91,
    bendsOver60PerHour: 52,
    twistsPerHour: 35,
  },
  {
    role: "Job Role 21",
    lumbarScore: 385,
    bendsPerHour: 78,
    bendsOver60PerHour: 5,
    twistsPerHour: 32,
  },
  {
    role: "Job Role 16",
    lumbarScore: 306,
    bendsPerHour: 87,
    bendsOver60PerHour: 39,
    twistsPerHour: 19,
  },
  {
    role: "Job Role 20",
    lumbarScore: 321,
    bendsPerHour: 75,
    bendsOver60PerHour: 21,
    twistsPerHour: 33,
  },
  {
    role: "Job Role 18",
    lumbarScore: 336,
    bendsPerHour: 89,
    bendsOver60PerHour: 21,
    twistsPerHour: 43,
  },
  {
    role: "Job Role 3",
    lumbarScore: 344,
    bendsPerHour: 82,
    bendsOver60PerHour: 8,
    twistsPerHour: 12,
  },
  {
    role: "Job Role 10",
    lumbarScore: 397,
    bendsPerHour: 84,
    bendsOver60PerHour: 21,
    twistsPerHour: 27,
  },
  {
    role: "Job Role 10",
    lumbarScore: 387,
    bendsPerHour: 84,
    bendsOver60PerHour: 21,
    twistsPerHour: 27,
  },
  {
    role: "Job Role 10",
    lumbarScore: 385,
    bendsPerHour: 84,
    bendsOver60PerHour: 21,
    twistsPerHour: 27,
  },
  {
    role: "Job Role 10",
    lumbarScore: 383,
    bendsPerHour: 84,
    bendsOver60PerHour: 21,
    twistsPerHour: 27,
  },
  {
    role: "Job Role 10",
    lumbarScore: 368,
    bendsPerHour: 84,
    bendsOver60PerHour: 21,
    twistsPerHour: 27,
  },
  {
    role: "Job Role 10",
    lumbarScore: 344,
    bendsPerHour: 84,
    bendsOver60PerHour: 21,
    twistsPerHour: 27,
  },
  {
    role: "Job Role 10",
    lumbarScore: 336,
    bendsPerHour: 84,
    bendsOver60PerHour: 21,
    twistsPerHour: 27,
  },
  {
    role: "Job Role 10",
    lumbarScore: 321,
    bendsPerHour: 84,
    bendsOver60PerHour: 21,
    twistsPerHour: 27,
  },
  {
    role: "Job Role 10",
    lumbarScore: 306,
    bendsPerHour: 84,
    bendsOver60PerHour: 21,
    twistsPerHour: 27,
  },
];

// const getColor = (value: number, max: number): string => {
//   const percent = (value / max) * 100;
//   if (percent > 75) return "#FFCCCB"; // Light red
//   if (percent > 50) return "#FFFFE0"; // Light yellow
//   return "#DFF0D8"; // Light green
// };

// const getColor = (value: number, max: number): string => {
//   if (value > 90) return "rgba(92, 230, 92, 0.7)"; // Light green
//   if (value > 85) return "rgba(173, 255, 47, 0.6)"; // Light green-yellow
//   if (value > 80) return "rgba(255, 255, 224, 0.6)"; // Light yellow
//   if (value > 70) return "rgba(255, 204, 203, 0.6)"; // Light red
//   if (value > 60) return "rgba(255, 165, 0, 0.6)"; // Orange
//   if (value > 55) return "rgba(255, 69, 0, 0.8)"; // Red-orange
//   if (value > 50) return "rgba(255, 0, 0, 0.6)"; // Red
//   return "rgba(223, 240, 216, 0.6)"; // Light green with transparency
// };

const getColorForColumn = (column: keyof DataRow, value: number): string => {
  switch (column) {
    case "lumbarScore":
      if (value >= 400) return "#ed7668"; // Dark red
      if (value >= 380) return "#f2b476"; // Light orange
      if (value >= 322) return "#eedc6b"; // Light yellow
      if (value >= 280) return "#e5e579"; // Yellow-green
      if (value >= 250) return "#9cc593"; // Light green
      return "#f2f2f2"; // Default light gray

    case "bendsPerHour":
      if (value >= 90) return "#ed7668"; // Dark red
      if (value >= 85) return "#f2b476"; // Light orange
      if (value >= 84) return "#eedc6b"; // Light yellow
      if (value >= 75) return "#e5e579"; // Yellow-green
      if (value >= 70) return "#9cc593"; // Light green
      return "#f2f2f2"; // Default light gray

    case "bendsOver60PerHour":
      if (value >= 40) return "#ed7668"; // Dark red
      if (value >= 25) return "#f2b476"; // Light orange
      if (value >= 15) return "#eedc6b"; // Light yellow
      if (value >= 5) return "#9cc593"; // Light green
      return "#9cc593"; // Light green

    case "twistsPerHour":
      if (value >= 43) return "#ed7668"; // Dark red
      if (value >= 33) return "#f2b476"; // Light orange
      if (value >= 23) return "#eedc6b"; // Light yellow
      if (value >= 13) return "#e5e579"; // Yellow-green
      return "#9cc593"; // Light green

    default:
      return "#f2f2f2"; // Default light gray
  }
};

type Order = "asc" | "desc";

const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = <T,>(
  order: Order,
  orderBy: keyof T
): ((a: T, b: T) => number) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = <T,>(
  array: T[],
  comparator: (a: T, b: T) => number
): T[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

interface TableOneProps {
  highlighting: boolean;
  currentArea: string;
  currentSubArea: string | null;
}

function TableOne({
  highlighting,
  currentArea,
  currentSubArea,
}: TableOneProps) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof DataRow>("role");

  const handleRequestSort = (property: keyof DataRow) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = stableSort(data, getComparator(order, orderBy));

  const maxLumbarScore = Math.max(...data.map((d) => d.lumbarScore));
  const maxBendsPerHour = Math.max(...data.map((d) => d.bendsPerHour));
  const maxBendsOver60PerHour = Math.max(
    ...data.map((d) => d.bendsOver60PerHour)
  );
  const maxTwistsPerHour = Math.max(...data.map((d) => d.twistsPerHour));

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontWeight: "bold" }}>
          Job Role Ergonomics Work Zone {currentArea}{" "}
          {currentArea && currentArea != "fullSite" && currentSubArea}
        </Typography>
      </Box>
      <TableContainer
        component={Paper}
        style={{ maxHeight: 325, overflow: "auto" }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {[
                { id: "role", label: "" },
                { id: "lumbarScore", label: "Lumbar Score" },
                { id: "bendsPerHour", label: "Bends Per Hour" },
                { id: "bendsOver60PerHour", label: "Bends>60 Per Hour" },
                { id: "twistsPerHour", label: "Twists Per Hour" },
              ].map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align="center"
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={{
                    padding: "2px 8px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    fontWeight: "bold",
                    textOverflow: "ellipsis",

                    // fontSize: "0.75rem", // Smaller font size
                  }}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={() =>
                      handleRequestSort(headCell.id as keyof DataRow)
                    }
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, index) => (
              <TableRow key={index} sx={{ height: 24 }}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    padding: "2px 8px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: "bold",
                    // fontSize: "0.75rem", // Smaller font size
                  }} // Reduced padding and nowrap
                >
                  {row.role}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn("lumbarScore", row.lumbarScore)
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                    // fontSize: "0.75rem", // Smaller font size
                  }}
                >
                  {row.lumbarScore}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn("bendsPerHour", row.bendsPerHour)
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                    // fontSize: "0.75rem", // Smaller font size
                  }}
                >
                  {row.bendsPerHour}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn(
                          "bendsOver60PerHour",
                          row.bendsOver60PerHour
                        )
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                    // fontSize: "0.75rem", // Smaller font size
                  }}
                >
                  {row.bendsOver60PerHour}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn("twistsPerHour", row.twistsPerHour)
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                    // fontSize: "0.75rem", // Smaller font size
                  }}
                >
                  {row.twistsPerHour}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default TableOne;
