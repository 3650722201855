import { Box, Card, Container, Divider, Tab, Typography } from "@mui/material";
import NotificationsControlBar from "../components/notificationsPage/components/NotificationsControlBar";
import NotificationCard from "../components/notificationsPage/components/NotificationCard";
import { useState } from "react";
import Environmental from "../components/notificationsPage/Environmental/Environmental";
import Ergonomics from "../components/notificationsPage/Ergonomics/Ergonomics";
import Efficiency from "../components/notificationsPage/Efficiency/Efficiency";
import Forklifts from "../components/notificationsPage/Forklifts/Forklifts";

function NotificationsPage() {
  const [currentTab, setCurrentTab] = useState<string>("environmental");
  const [currentStatusFilter, setCurrentStatusFilter] = useState<string>("all");

  return (
    <Container maxWidth="xl">
      <Typography sx={{ marginBottom: "1%" }} variant="h5">
        Notification Center
      </Typography>
      <Box>
        <NotificationsControlBar
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          currentStatusFilter={currentStatusFilter}
          setCurrentStatusFilter={setCurrentStatusFilter}
        />
      </Box>
      <Box>
        <Box display={"flex"} justifyContent={"space-evenly"}>
          <Typography fontWeight={"bold"}>Category</Typography>
          <Divider orientation="vertical" flexItem />
          <Typography fontWeight={"bold"}>Status</Typography>
          <Divider orientation="vertical" flexItem />
          <Typography fontWeight={"bold"}>Description</Typography>
          <Divider orientation="vertical" flexItem />
          <Typography fontWeight={"bold"}>AI Recommended Action</Typography>
          <Divider orientation="vertical" flexItem />
        </Box>
        <Box>
          {currentTab === "all" && <Environmental />}
          {currentTab === "environmental" && <Environmental />}
          {currentTab === "ergonomics" && <Ergonomics />}
          {currentTab === "maintenance" && <Environmental />}
          {currentTab === "efficiency" && <Efficiency />}
          {currentTab === "forklifts" && <Forklifts />}
        </Box>
      </Box>
    </Container>
  );
}

export default NotificationsPage;
