import { useEffect } from "react";
import { Box, Container } from "@mui/material";
import TableOne from "./components/TableOne";
import TableTwo from "./components/TableTwo";
import axios from "axios";

interface EnvironmentalProps {
  highlighting: boolean;
  dateRange: [Date | undefined, Date | undefined];
  setDateRange: (dateRange: [Date | undefined, Date | undefined]) => void;
  currentArea: string;
  currentSubArea: string | null;
}

function Environmental({
  highlighting,
  dateRange,
  setDateRange,
  currentArea,
  currentSubArea,
}: EnvironmentalProps) {
  const startDate = dateRange[0]?.toISOString().split("T")[0]; // Format date to 'YYYY-MM-DD'
  const endDate = dateRange[1]?.toISOString().split("T")[0]; // Format date to 'YYYY-MM-DD'

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await axios.get(
          "http://localhost:8000/environmental_data",
          {
            params: {
              start_date: startDate,
              end_date: endDate,
            },
          }
        );
      } catch (error) {}
    };

    if (startDate && endDate) {
      fetchData();
    }
  }, [startDate, endDate]); // Only run when startDate and endDate change

  return (
    <Container>
      <Box>
        <TableOne
          highlighting={highlighting}
          currentArea={currentArea}
          currentSubArea={currentSubArea}
        />
      </Box>
      <Box sx={{ marginTop: "1%" }}>
        <TableTwo
          highlighting={highlighting}
          currentArea={currentArea}
          currentSubArea={currentSubArea}
        />
      </Box>
    </Container>
  );
}

export default Environmental;
