import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Typography,
  Container,
  Link,
  Snackbar,
  Alert,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ModjoulIcon from "../images/modjoul-icon.png";

const apiUrl = process.env.REACT_APP_API_URL;
const theme = createTheme();

interface LoginProps {
  setIsAuth: (auth: boolean) => void;
}

export default function Login({ setIsAuth }: LoginProps) {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loginError, setLoginError] = useState<string>(""); // Error message state
  const [openError, setOpenError] = useState<boolean>(false); // Snackbar visibility state
  const navigate = useNavigate();

  const login = async (url: string, data: Object) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
      });

      if (response.status === 200 && response.data.token) {
        setIsAuth(true);
        localStorage.setItem("jwt", response.data.token);
        navigate("/map");
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      setLoginError("Login failed. Please check your credentials.");
      setOpenError(true); // Show Snackbar on error
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoginError(""); // Reset error message

    const isValidEmail = /\S+@\S+\.\S+/.test(email); // Simplified email validation

    if (!email || !isValidEmail) {
      setLoginError("Please enter a valid email.");
      setOpenError(true);
      return;
    }

    if (!password) {
      setLoginError("Password is required.");
      setOpenError(true);
      return;
    }

    const data = { email, password };
    // login("http://localhost:4000/login", data); // Use this URL for local dev
    // login("https://18.232.163.40:4000/login", data); // Use this endpoint for production dev
    login(`${apiUrl}/login`, data); // Use for HTTPS production endpoint
  };

  const handleCloseSnackbar = () => {
    setOpenError(false); // Close Snackbar
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={ModjoulIcon}
            alt="Modjoul Logo"
            style={{ width: "100px", height: "auto" }}
          />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Sign In
            </Button>
            <Grid container textAlign={"center"}>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* Snackbar for error notifications */}
        <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: "100%" }}>
            {loginError}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
}
