import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Box,
  Typography,
} from "@mui/material";

// Define the data type
interface DataRow {
  role: string;
  travel: number;
  forklift: number;
  pedestrianAlerts: number;
  pedestrianInteractions: number;
  forkliftInteractions: number;
}

// Sample data from the image
const data: DataRow[] = [
  {
    role: "Employee 1",
    travel: 50,
    forklift: 66,
    pedestrianAlerts: 14,
    pedestrianInteractions: 49,
    forkliftInteractions: 44,
  },
  {
    role: "Employee 2",
    travel: 40,
    forklift: 85,
    pedestrianAlerts: 17,
    pedestrianInteractions: 20,
    forkliftInteractions: 33,
  },
  {
    role: "Employee 3",
    travel: 53,
    forklift: 62,
    pedestrianAlerts: 14,
    pedestrianInteractions: 27,
    forkliftInteractions: 44,
  },
  {
    role: "Employee 4",
    travel: 55,
    forklift: 79,
    pedestrianAlerts: 6,
    pedestrianInteractions: 12,
    forkliftInteractions: 33,
  },
  {
    role: "Employee 5",
    travel: 36,
    forklift: 72,
    pedestrianAlerts: 2,
    pedestrianInteractions: 36,
    forkliftInteractions: 38,
  },
  {
    role: "Employee 6",
    travel: 39,
    forklift: 70,
    pedestrianAlerts: 2,
    pedestrianInteractions: 47,
    forkliftInteractions: 37,
  },
  {
    role: "Employee 7",
    travel: 43,
    forklift: 44,
    pedestrianAlerts: 7,
    pedestrianInteractions: 22,
    forkliftInteractions: 43,
  },
  {
    role: "Employee 8",
    travel: 47,
    forklift: 56,
    pedestrianAlerts: 6,
    pedestrianInteractions: 33,
    forkliftInteractions: 33,
  },
  {
    role: "Employee 9",
    travel: 35,
    forklift: 68,
    pedestrianAlerts: 10,
    pedestrianInteractions: 19,
    forkliftInteractions: 49,
  },
  {
    role: "Employee 10",
    travel: 58,
    forklift: 60,
    pedestrianAlerts: 14,
    pedestrianInteractions: 19,
    forkliftInteractions: 32,
  },
  {
    role: "Employee 11",
    travel: 43,
    forklift: 83,
    pedestrianAlerts: 19,
    pedestrianInteractions: 20,
    forkliftInteractions: 38,
  },
];

const getColorForColumn = (column: keyof DataRow, value: number): string => {
  switch (column) {
    case "travel":
      if (value >= 50) return "#9cc593"; // Light green
      if (value >= 45 && value < 50) return "#e5e579"; // Yellow-green
      if (value >= 40 && value < 45) return "#eedc6b"; // Light yellow
      if (value >= 38 && value < 40) return "#f2b476"; // Light orange
      if (value < 38) return "#ed7668"; // Dark red
      return "#f2f2f2"; // Default light gray

    case "forklift":
      if (value >= 85) return "#9cc593"; // Light green
      if (value >= 80 && value < 85) return "#e5e579"; // Yellow-green
      if (value >= 75 && value < 80) return "#eedc6b"; // Light yellow
      if (value >= 70 && value < 75) return "#f2b476"; // Light orange
      if (value < 70) return "#ed7668"; // Dark red
      return "#f2f2f2"; // Default light gray

    case "pedestrianAlerts":
      if (value <= 5) return "#9cc593"; // Light green
      if (value >= 5 && value < 7) return "#e5e579"; // Yellow-green
      if (value >= 7 && value < 9) return "#eedc6b"; // Light yellow
      if (value >= 9 && value < 11) return "#f2b476"; // Light orange
      if (value >= 11) return "#ed7668"; // Dark red
      return "#f2f2f2"; // Default light gray

    case "pedestrianInteractions":
      if (value < 22) return "#9cc593"; // Light green
      if (value >= 22 && value < 25) return "#e5e579"; // Yellow-green
      if (value >= 25 && value < 28) return "#eedc6b"; // Light yellow
      if (value >= 28 && value < 31) return "#f2b476"; // Light orange
      if (value >= 31) return "#ed7668"; // Dark red
      return "#f2f2f2"; // Default light gray

    case "forkliftInteractions":
      if (value < 34) return "#9cc593"; // Light green
      if (value >= 34 && value < 37) return "#e5e579"; // Yellow-green
      if (value >= 37 && value < 40) return "#eedc6b"; // Light yellow
      if (value >= 40 && value < 43) return "#f2b476"; // Light orange
      if (value >= 43) return "#ed7668"; // Dark red
      return "#f2f2f2"; // Default light gray

    default:
      return "#f2f2f2"; // Default light gray
  }
};

type Order = "asc" | "desc";

const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = <T,>(
  order: Order,
  orderBy: keyof T
): ((a: T, b: T) => number) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = <T,>(
  array: T[],
  comparator: (a: T, b: T) => number
): T[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

interface TableTwoProps {
  highlighting: boolean;
  dateRange: [Date | undefined, Date | undefined];
  currentArea: string;
  currentSubArea: string | null;
}

function TableTwo({
  highlighting,
  dateRange,
  currentArea,
  currentSubArea,
}: TableTwoProps) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof DataRow>("role");

  const handleRequestSort = (property: keyof DataRow) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = stableSort(data, getComparator(order, orderBy));

  const maxTravel = Math.max(...data.map((d) => d.travel));
  const maxForklift = Math.max(...data.map((d) => d.forklift));
  const maxPedestrianAlerts = Math.max(...data.map((d) => d.pedestrianAlerts));
  const maxPedestrianInteractions = Math.max(
    ...data.map((d) => d.pedestrianInteractions)
  );
  const maxForkliftInteractions = Math.max(
    ...data.map((d) => d.forkliftInteractions)
  );

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontWeight: "bold" }}>
          Forklift Utilization by Employee {currentArea}
          {currentArea && currentArea != "fullSite" && currentSubArea}
        </Typography>
      </Box>
      <TableContainer
        component={Paper}
        style={{ maxHeight: 325, overflow: "auto" }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {[
                { id: "role", label: "" },
                { id: "travel", label: "Travel %" },
                { id: "forklift", label: "Forklift %" },
                { id: "pedestrianAlerts", label: "Pedestrian Alerts" },
                {
                  id: "pedestrianInteractions",
                  label: "Pedestrian Interactions",
                },
                { id: "forkliftInteractions", label: "Forklift Interactions" },
              ].map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align="center"
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={{
                    padding: "2px 8px",
                    // whiteSpace: "nowrap",
                    overflow: "hidden",
                    fontWeight: "bold",
                    textOverflow: "ellipsis",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={() =>
                      handleRequestSort(headCell.id as keyof DataRow)
                    }
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, index) => (
              <TableRow key={index} sx={{ height: 24 }}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    padding: "2px 8px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: "bold",
                  }}
                >
                  {row.role}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn("travel", row.travel)
                      : undefined,
                    padding: "2px 8px",
                    fontWeight: "bold",
                  }}
                >
                  {row.travel}%
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn("forklift", row.forklift)
                      : undefined,
                    padding: "2px 8px",
                    fontWeight: "bold",
                  }}
                >
                  {row.forklift}%
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn(
                          "pedestrianAlerts",
                          row.pedestrianAlerts
                        )
                      : undefined,
                    padding: "2px 8px",
                    fontWeight: "bold",
                  }}
                >
                  {row.pedestrianAlerts}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn(
                          "pedestrianInteractions",
                          row.pedestrianInteractions
                        )
                      : undefined,
                    padding: "2px 8px",
                    fontWeight: "bold",
                  }}
                >
                  {row.pedestrianInteractions}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn(
                          "forkliftInteractions",
                          row.forkliftInteractions
                        )
                      : undefined,
                    padding: "2px 8px",
                    fontWeight: "bold",
                  }}
                >
                  {row.forkliftInteractions}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default TableTwo;
