import { Box } from "@mui/material";
import NotificationCard from "../components/NotificationCard";

const mockData = [
  {
    category: "Efficiency Ergonomics",
    status: "Not Started",
    description:
      "When >5 workers are in the Mixing area at once, their wait time significantly increases.",
    aiRecommendedAction: "Consider assigning 5 or less workers to this area.",
    statusColor: "gray",
    date: "Wednesday, 12:45pm, 08/10/24",
  },
  {
    category: "Efficiency",
    status: "Not Started",
    description:
      "Worker work time increases and wait time decreases when Manager Andy is walking around on the plant floor.",
    aiRecommendedAction: "Have managers keep a closer eye on workers.",
    statusColor: "orange",
    date: "Wednesday, 12:16pm, 08/10/24",
  },
  {
    category: "Efficiency Ergonomics",
    status: "Not Started",
    description:
      "Nick Thilen and Imtenon Towfeeq are assigned to Mixing today, but have been away from their stations for >30 minutes. Other workers assigned to mixing have increased MET scores due to their absence.",
    aiRecommendedAction:
      "Have Nick Thilen and Imtenon Towfeeq return to their stations.",
    statusColor: "red",
    date: "Tuesday, 4:45pm, 08/09/24",
  },
  {
    category: "Efficiency Forklift",
    status: "Not Started",
    description:
      "Forklift to forklift passing each other near corridor 3 are creating congestion leading to increased collision risk and slower speeds.",
    aiRecommendedAction:
      "Consider rerouting forklifts that can travel a different route.",
    statusColor: "orange",
    date: "Tuesday, 2:46pm, 08/09/24",
  },
  {
    category: "Efficiency",
    status: "Not Started",
    description:
      "Sarah Essieh has been the only person working at Packaging for 2:53(hr:mm). There is supposed to be a minimum of two workers at this station.",
    aiRecommendedAction: "Assign another worker to Packaging to help Sarah.",
    statusColor: "red",
    date: "Tuesday, 10:45am, 08/09/24",
  },
  {
    category: "Efficiency Forklift",
    status: "Not Started",
    description:
      "Forklift Driver Will and Forklift Driver Nick have the same job role and are both traveling from loading dock to imports area but Nick is taking a less efficient route. Will’s back-and-forth route is 255ft and takes 28 seconds, while Nick's route is 890ft and takes 1:55 (m:s).",
    aiRecommendedAction: "Coach Nick on how to take a more efficient route.",
    statusColor: "orange",
    date: "Tuesday, 8:23am, 08/09/24",
  },
];

function Efficiency() {
  return (
    <Box>
      {mockData.map((cardData, index) => (
        <NotificationCard key={index} cardData={cardData} />
      ))}
    </Box>
  );
}

export default Efficiency;
