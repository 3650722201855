import Card from "@mui/material/Card";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Container,
  Box,
  CardContent,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Operations from "../components/dataPage/components/Operations";
import Safety from "../components/dataPage/components/Safety";
import Efficiency from "../components/dataPage/components/Efficiency";
import Environmental from "../components/dataPage/components/Environmental";
import EnvironmentalTwo from "../components/dataPage/components/EnvironmentalTwo";
import ControlBar from "../components/dataPage/components/ControlBar";
import QuickSightDashboard from "../components/dataPage/components/QuickSightDashboard";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

function DataPage() {
  const [currentDate, setCurrentDate] = useState<number>(25);
  const [currentShift, setCurrentShift] = useState<string>("A");
  const [dataDisplay, setDataDisplay] = useState<string | null>(
    "environmental"
  );

  const [embedUrl, setEmbedUrl] = useState("");

  const handleDataDisplay = (
    event: React.MouseEvent<HTMLElement>,
    newDisplay: string | null
  ) => {
    setDataDisplay(newDisplay);
  };

  const capitalizeWord = (word: string) => {
    return word.replace(/\b\w/g, function (char: string) {
      return char.toUpperCase();
    });
  };

  useEffect(() => {
    const fetchDashboard = async () => {
      const token = localStorage.getItem("jwt");
      // let response = await axios.get("http://18.232.163.40:4000/quicksight-url", 
      // let response = await axios.get("https://localhost:4000/quicksight-url", 
      let response = await axios.get(`${apiUrl}/quicksight-url`, 
        {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Embed Url:", response.data.embedUrl);
      setEmbedUrl(response.data.embedUrl);
    };

    fetchDashboard();
  }, []);

  return (
    // <Container maxWidth="xl">
    <>
      {embedUrl ? (
        <QuickSightDashboard embedUrl={embedUrl} />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      )}
      {/* <Card>
        <CardContent>
        <Box display="flex" justifyContent="center" sx={{ width: "100%" }}>
        <ToggleButtonGroup
        exclusive
        aria-label="text alignment"
        value={dataDisplay}
        onChange={handleDataDisplay}
        >
        <ToggleButton
        sx={{ minWidth: "125px" }}
        value="environmental"
        aria-label="right aligned"
        >
        <Typography sx={{ fontWeight: "bold", textTransform: "none" }}>
        Environmental
        </Typography>
        </ToggleButton>
        <ToggleButton
        sx={{ minWidth: "125px" }}
        value="efficiency"
        aria-label="right aligned"
        >
        <Typography sx={{ fontWeight: "bold", textTransform: "none" }}>
        Efficiency
        </Typography>
        </ToggleButton>
        <ToggleButton
        sx={{ minWidth: "125px" }}
        value="maintenance"
        aria-label="justified"
        >
        <Typography sx={{ fontWeight: "bold", textTransform: "none" }}>
        Maintenance
        </Typography>
        </ToggleButton>
        <ToggleButton sx={{ minWidth: "125px" }} value="operations">
        <Typography sx={{ fontWeight: "bold", textTransform: "none" }}>
        Operations
        </Typography>
        </ToggleButton>
        <ToggleButton
        sx={{ minWidth: "125px" }}
        value="safety"
        aria-label="centered"
        >
        <Typography sx={{ fontWeight: "bold", textTransform: "none" }}>
        Safety
        </Typography>
        </ToggleButton>
        </ToggleButtonGroup>
        <FormControl sx={{ m: 1, minWidth: 150 }}>
        <InputLabel
        sx={{ fontWeight: "bold" }}
        id="demo-simple-select-helper-label"
        >
        Date
        </InputLabel>
        <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={currentDate.toString()}
        label="Date"
        onChange={(e: SelectChangeEvent) => {
          setCurrentDate(Number(e.target.value));
          console.log(currentDate);
          }}
          >
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={26}>26</MenuItem>
          <MenuItem value={27}>27</MenuItem>
          </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 150 }}>
          <InputLabel
          sx={{ fontWeight: "bold" }}
          id="demo-simple-select-helper-label"
          >
          Shift
          </InputLabel>
          <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={currentShift}
          label="Date"
          onChange={(e: SelectChangeEvent) => {
            setCurrentShift(e.target.value);
            console.log(currentDate);
            }}
            >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"A"}>A</MenuItem>
            <MenuItem value={"B"}>B</MenuItem>
            <MenuItem value={"C"}>C</MenuItem>
            <MenuItem value={"D"}>D</MenuItem>
            </Select>
            </FormControl>
            </Box>
            <ControlBar dataDisplay={dataDisplay} />
            {(dataDisplay === "operations" && (
              <Operations
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              />
              )) ||
              (dataDisplay === "safety" && <Safety />) ||
              (dataDisplay === "efficiency" && <Efficiency />) ||
              (dataDisplay === "environmental" && <Environmental />) ||
              (dataDisplay === "environmentalTwo" && <EnvironmentalTwo />)}
              </CardContent>
              </Card> */}
    </>
    // </Container>
  );
}

export default DataPage;
