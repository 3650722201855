import { Box, Card, Radio, Typography } from "@mui/material";
import { useState } from "react";

interface NotificationCardProps {
  cardData: {
    category: string;
    status: string;
    description: string;
    aiRecommendedAction: string;
    statusColor: string;
    date: string;
  };
}

function NotificationCard({
  cardData: {
    category,
    status,
    description,
    aiRecommendedAction,
    statusColor,
    date,
  },
}: NotificationCardProps) {
  return (
    <Box display={"flex"} alignItems={"center"}>
      <Box>
        <Radio />
      </Box>
      <Card
        sx={{
          marginTop: "2px",
          marginBottom: "8px",
          marginRight: "8px",
          display: "flex",
          alignItems: "stretch",
          minHeight: "90px",
          width: "100%",
        }}
      >
        <Box display={"flex"} flex={12}>
          <Box
            sx={{
              width: "15px",
              backgroundColor: statusColor,
            }}
          />

          <Box
            sx={{ marginLeft: "20px" }}
            display={"flex"}
            flexDirection={"column"}
            flex={1.5}
          >
            <Typography sx={{ marginTop: "2px" }} fontSize={"11px"}>
              {date}
            </Typography>
            <Typography sx={{ marginTop: "4%" }} flex={2}>
              {category}
            </Typography>
          </Box>
          <Typography flex={1}>{status}</Typography>
          <Typography flex={2}>{description}</Typography>
          <Typography sx={{ marginLeft: "5%" }} flex={2}>
            {aiRecommendedAction}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}
export default NotificationCard;
