import { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Typography, Container, Paper, CircularProgress, Box } from "@mui/material";
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

type Data = {
  time: string;
  maintTime: number;
};

interface ChartOneProps {
  highlighting: boolean;
  dateRange: [Date | undefined, Date | undefined];
  currentArea: string;
  currentSubArea: string | null;
}
const ChartOne = ({highlighting,dateRange,currentArea,currentSubArea}: ChartOneProps) => {
  const [data, setData] = useState<Data[]>([]);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    if (!dateRange[0] || !dateRange[1]) {
      return
    }

    const fetchData = async () => {
      try {
        setLoading(true)
        let response = await axios.get(`${apiUrl}/api/maintenance-time-by-hour-today`, {
          params: {
            startDate: dateRange[0]?.toISOString(),  
            endDate: dateRange[1]?.toISOString()    
          }
        })
        console.log("LATEST DATA:", response.data)

        setData(response.data)
        
      } catch (error) {
        console.log("There was an error fetching Maintenance Time by day data")
      } finally {
        setLoading(false)
      }
    }
    fetchData()
    },[dateRange])

  return (
    <Paper elevation={3} sx={{ padding: 3, marginTop: 3 }}>
      <Typography variant="h6" fontWeight="bold" fontSize={"15px"} gutterBottom>
        Maintenance Time Today
      </Typography>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", padding: "20px" }}>
          <CircularProgress />
        </Box>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis domain={[0, 60]} />
            <Tooltip />
            <Legend />
            <Bar dataKey="maintTime" fill="#4169E1" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </Paper>
  );
};

export default ChartOne;
