import { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Typography, Container, Paper, CircularProgress, Box } from "@mui/material";
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const ChartOne = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); 
      try {
        const response = await axios.get(`${apiUrl}/api/zone-occupation-today`);
        setData(response.data); // Populate chart data
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); 
      }
    };
    fetchData();
  }, []);

  return (
    <Paper elevation={3} sx={{ padding: 3, marginTop: 3 }}>
      <Typography variant="h6" fontWeight="bold" fontSize={"15px"} gutterBottom>
        Employees in CP Area Over Time Today
      </Typography>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", padding: "20px" }}>
          <CircularProgress />
        </Box>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="employees" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </Paper>
  );
};

export default ChartOne;
