import { Box } from "@mui/material";
import NotificationCard from "../components/NotificationCard";

const mockData = [
  {
    category: "Operations",
    status: "Not Started",
    description:
      "Work Time percentage low in shift C. Increased maintenance issues at stations with new employees.",
    aiRecommendedAction: "Send new employees for additional training",
    statusColor: "gray",
    date: "Wednesday, 12:45pm, 08/10/24",
  },
  {
    category: "Maintenance",
    status: "Not Started",
    description:
      "Overall Maintenance Zone Percentage Time down 21% over last week.",
    aiRecommendedAction: "Keep it up!",
    statusColor: "green",
    date: "Wednesday, 12:15pm, 08/10/24",
  },
  {
    category: "Maintenance",
    status: "Not Started",
    description: "Threshold ALERT, TL01 Maintenance % for Grinder crossed 40%",
    aiRecommendedAction: "Schedule maintenance7 weeks from now HERE",
    statusColor: "orange",
    date: "Tuesday, 4:43pm, 08/09/24",
  },
  {
    category: "Environmental",
    status: "Not Started",
    description:
      "High temperatures detected in area CL09: Temperature has exceeded 90o for >1hr.",
    aiRecommendedAction:
      "Send QC team to ensure batch has not been compromised",
    statusColor: "orange",
    date: "Tuesday, 2:45pm, 08/10/24",
  },
  {
    category: "Forklifts",
    status: "Not Started",
    description:
      "FLTF00129 usage increased 11% week over week from 43% to 54%.",
    aiRecommendedAction: "Send new employees for additional training",
    statusColor: "gray",
    date: "Tuesday, 10:45am, 08/10/24",
  },
  {
    category: "Ergonomics",
    status: "Not Started",
    description:
      "Associate Nick Thilen exceeded daily bend threshold with 31% of shift complete. Associate assigned to brake-pad processing.",
    aiRecommendedAction: "Have Nick Thilen meet with physical trainer.",
    statusColor: "gray",
    date: "Tuesday, 8:23am, 08/09/24",
  },
];

function Environmental() {
  return (
    <Box>
      {mockData.map((cardData, index) => (
        <NotificationCard key={index} cardData={cardData} />
      ))}
    </Box>
  );
}

export default Environmental;
