import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Box,
  Typography,
  CircularProgress, // Import CircularProgress for the loading spinner
} from "@mui/material";
import axios from "axios";

// Define the data type
interface DataRow {
  employeeName: string;
  totalTimeSpentInMillis: number;
  totalTimeSpent: string;
}

interface TableTwoProps {
  highlighting: boolean;
  dateRange: [Date | undefined, Date | undefined];
  currentArea: string;
  currentSubArea: string | null;
}

// Color coding based on totalTimeSpentInMillis
const getColorForMillis = (value: number): string => {
  if (value >= 4 * 60 * 60 * 1000) return "#0c5720";   // Dark green for 4+ hours
  if (value >= 3 * 60 * 60 * 1000) return "#47a02f";   // Green for 3-4 hours
  if (value >= 2 * 60 * 60 * 1000) return "#75d62b";   // Light green for 2-3 hours
  if (value >= 1 * 60 * 60 * 1000) return "#99c93e";   // Yellow-green for 1-2 hours
  if (value >= 30 * 60 * 1000)     return "#b0c93e";   // Light yellow-green for 30-60 min
  if (value >= 10 * 60 * 1000)     return "#d99134";   // Orange for 10-30 minutes
  if (value >= 5 * 60 * 1000)      return "#d96b34";   // Dark orange for 5-10 minutes
  return "#f2f2f2";                                // Default light gray for < 5 minutes
};

type Order = "asc" | "desc";

// Sorting logic based on `totalTimeSpentInMillis`
const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T): number => {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
};

const getComparator = <T,>(
  order: Order,
  orderBy: keyof T
): ((a: T, b: T) => number) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = <T,>(
  array: T[],
  comparator: (a: T, b: T) => number
): T[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

function TableTwo({
  highlighting,
  dateRange,
  currentArea,
  currentSubArea,
}: TableTwoProps) {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof DataRow>("totalTimeSpentInMillis");
  const [data, setData] = useState<DataRow[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Loading state

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (!dateRange[0] || !dateRange[1]) {
      console.log("Please select a date range");
      return;
    }

    const fetchData = async () => {
      setLoading(true); // Start loading when the fetch begins
      try {
        const response = await axios.get(`${apiUrl}/api/top-devices-in-zones`, {
          params: {
            startDate: dateRange[0]?.toISOString(),
            endDate: dateRange[1]?.toISOString(),
          },
        });
        console.log("Data received:", response.data);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Stop loading when the fetch ends (success or failure)
      }
    };
    fetchData();
  }, [dateRange]);

  const handleRequestSort = (property: keyof DataRow) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = data ? stableSort(data, getComparator(order, "totalTimeSpentInMillis")) : [];

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ maxHeight: 325, overflow: "auto" }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            paddingTop: "1%",
            textAlign: "left",
            fontSize: "15px",
            marginLeft: "2%",
          }}
        >
          Employees with Most Time In The Area {currentArea}{" "}
          {currentArea && currentArea !== "fullSite" && currentSubArea}
        </Typography>
        {loading ? (
          // Display the loading spinner when loading state is true
          <Box sx={{ display: "flex", justifyContent: "center", padding: "20px" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {[
                  { id: "employeeName", label: "Identifier" },
                  { id: "totalTimeSpentInMillis", label: "Total Time Spent" },
                ].map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align="right"
                    sortDirection={orderBy === headCell.id ? order : false}
                    sx={{
                      padding: "2px 8px",
                      overflow: "hidden",
                      fontWeight: "bold",
                      textOverflow: "ellipsis",
                    }} // Reduced padding and nowrap
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleRequestSort(headCell.id as keyof DataRow)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((row, index) => (
                <TableRow key={index} sx={{ height: 24 }}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      padding: "2px 8px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontWeight: "bold",
                    }} // Reduced padding and nowrap
                  >
                    {row.employeeName}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      backgroundColor: highlighting
                        ? getColorForMillis(row.totalTimeSpentInMillis)
                        : undefined,
                      padding: "2px 8px", // Reduced padding
                      fontWeight: "bold",
                    }}
                  >
                    {row.totalTimeSpent}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  );
}

export default TableTwo;
