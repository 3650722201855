import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

interface NotificationsControlBarProps {
  currentTab: string;
  setCurrentTab: (tab: string) => void;
  currentStatusFilter: string;
  setCurrentStatusFilter: (filter: string) => void;
}

function NotificationsControlBar({
  currentTab,
  setCurrentTab,
  currentStatusFilter,
  setCurrentStatusFilter,
}: NotificationsControlBarProps) {
  const handleTabChange = (
    e: React.MouseEvent<HTMLElement>,
    newTab: string
  ) => {
    if (newTab !== null) {
      setCurrentTab(newTab);
    }
  };

  const handleCurrentStatusFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    newFilter: string
  ) => {
    if (newFilter !== null) {
      setCurrentStatusFilter(newFilter);
    }
  };
  return (
    // <Container sx={{ padding: 0, margin: 0 }}>
    <Box
      sx={{ padding: 0, margin: 0 }}
      display={"flex"}
      flexDirection={"column"}
    >
      <Box>
        <ToggleButtonGroup
          exclusive
          aria-label="Tab Selection"
          //   value={dataDisplay}
          //   onChange={handleDataDisplay}
          fullWidth
          value={currentTab}
          onChange={handleTabChange}
        >
          <ToggleButton sx={{ minWidth: "125px" }} value="all" aria-label="All">
            <Typography sx={{ fontWeight: "bold", textTransform: "none" }}>
              All
            </Typography>
          </ToggleButton>
          <ToggleButton
            sx={{ minWidth: "125px" }}
            value="environmental"
            aria-label="Environmental"
          >
            <Typography sx={{ fontWeight: "bold", textTransform: "none" }}>
              Environmental
            </Typography>
          </ToggleButton>
          <ToggleButton
            sx={{ minWidth: "125px" }}
            value="ergonomics"
            aria-label="Ergonomics"
          >
            <Typography sx={{ fontWeight: "bold", textTransform: "none" }}>
              Ergonomics
            </Typography>
          </ToggleButton>
          <ToggleButton
            sx={{ minWidth: "125px" }}
            value="maintenance"
            aria-label="Maintenance"
          >
            <Typography sx={{ fontWeight: "bold", textTransform: "none" }}>
              Maintenance
            </Typography>
          </ToggleButton>
          <ToggleButton
            sx={{ minWidth: "125px" }}
            value="efficiency"
            aria-label="Efficiency"
          >
            <Typography sx={{ fontWeight: "bold", textTransform: "none" }}>
              Efficiency
            </Typography>
          </ToggleButton>
          <ToggleButton
            sx={{ minWidth: "125px" }}
            value="forklifts"
            aria-label="Forklifts"
          >
            <Typography sx={{ fontWeight: "bold", textTransform: "none" }}>
              Forklifts
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <FormLabel
          sx={{ marginLeft: "7px", fontWeight: "bold" }}
          id="demo-row-radio-buttons-group-label"
        >
          Filter by:
        </FormLabel>
        <RadioGroup
          sx={{ marginLeft: "5px" }}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={currentStatusFilter}
          onChange={handleCurrentStatusFilterChange}
        >
          <FormControlLabel value="all" control={<Radio />} label="All" />
          <FormControlLabel value="unread" control={<Radio />} label="Unread" />
          <FormControlLabel
            value="notStarted"
            control={<Radio />}
            label="Not Started"
          />
          <FormControlLabel
            value="inProgress"
            control={<Radio />}
            label="In-Progress"
          />
          <FormControlLabel
            value="resolved"
            control={<Radio />}
            label="Resolved"
          />
        </RadioGroup>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        sx={{ marginBottom: "1%" }}
      >
        <FormLabel sx={{ marginLeft: "7px", fontWeight: "bold" }}>
          Sort by:
        </FormLabel>
        <FormControl size="small" sx={{ width: "13%", marginLeft: "2%" }}>
          <InputLabel id="demo-simple-select-label">DateTime</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            //   value={age}
            label="Age"
            //   onChange={handleChange}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ width: "13%", marginLeft: "2%" }}>
          <InputLabel id="demo-simple-select-label">Urgency</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            //   value={age}
            label="Urgency"
            //   onChange={handleChange}
          >
            <MenuItem value={"low"}>Low</MenuItem>
            <MenuItem value={"medium"}>Medium</MenuItem>
            <MenuItem value={"high"}>High</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
    // </Container>
  );
}

export default NotificationsControlBar;
