// src/data/SquareBoundsData.ts

// Define a tuple for latitude and longitude
type LatLngTuple = [number, number];

// Define the type for each square
interface Square {
  id: string;
  bounds: [LatLngTuple, LatLngTuple]; // Array of two lat/lng pairs
}

// Define the bounds for each square
const squareBoundsOne: [LatLngTuple, LatLngTuple] = [
  [51.4956, -0.076], 
  [51.4969, -0.0747],
];

const squareBoundsTwo: [LatLngTuple, LatLngTuple] = [
  [51.4928, -0.076], 
  [51.4942, -0.074],
];

const squareBoundsThree: [LatLngTuple, LatLngTuple] = [
  [51.4957, -0.0711], 
  [51.4964, -0.0738],
];

const squareBoundsFour: [LatLngTuple, LatLngTuple] = [
  [51.493, -0.0709], 
  [51.4937, -0.0737],
];

const squareBoundsFive: [LatLngTuple, LatLngTuple] = [
  [51.4947, -0.0729], 
  [51.49535, -0.0743],
];

const squareBoundsSix: [LatLngTuple, LatLngTuple] = [
  [51.4922, -0.0686], 
  [51.49263, -0.0722],
];

// Export the squares data as an array of Square objects
const Squares: Square[] = [
  { id: "square1", bounds: squareBoundsOne },
  { id: "square2", bounds: squareBoundsTwo },
  { id: "square3", bounds: squareBoundsThree },
  { id: "square4", bounds: squareBoundsFour },
  { id: "square5", bounds: squareBoundsFive },
  { id: "square6", bounds: squareBoundsSix },
];

export default Squares;
