import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface DateRangePickerComponentProps {
  dateRange: [Date | undefined, Date | undefined];
  setDateRange: (dateRange: [Date | undefined, Date | undefined]) => void;
}


const DateRangePickerComponent: React.FC<DateRangePickerComponentProps> = ({ dateRange, setDateRange }) => {
  
  const [startDate, endDate ] = dateRange
  

  // const [dateRange, setDateRange] = useState<
  //   [Date | undefined, Date | undefined]
  // >([undefined, undefined]);
  // const [startDate, endDate] = dateRange;
  return (
    <Box display="flex" justifyContent="center">
      {/* <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date as Date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        placeholderText="Start Date"
      />
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date as Date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        placeholderText="End Date"
        minDate={startDate}
      /> */}
      <Typography
        fontSize={"18px"}
        sx={{ marginRight: "5px", paddingLeft: "8px" }}
      >
        Date Range:
      </Typography>
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update: [Date | null, Date | null] | null) => {
          if (update) {
            const [start, end] = update;
            setDateRange([start || undefined, end || undefined]);
          } else {
            setDateRange([undefined, undefined]);
          }
        }}
        isClearable={true}
      />
    </Box>
  );
};

export default DateRangePickerComponent;
