import { Box, Breadcrumbs, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface BreadCrumsbProps {
  currentArea: string;
  setCurrentArea: Function;
  currentSubArea: string | null;
}

function BreadCrumbs({
  currentArea,
  setCurrentArea,
  currentSubArea,
}: BreadCrumsbProps) {
  return (
    <Box display={"flex"} alignItems={"center"}>
      {currentArea === "fullSite" ? (
        <ChevronRightIcon
          fontSize="large"
          sx={{ color: "gray", marginBottom: "2px" }}
        />
      ) : (
        <KeyboardArrowDownIcon
          fontSize="large"
          sx={{ color: "gray", marginBottom: "2px" }}
        />
      )}

      {currentArea === "fullSite" && (
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<ChevronRightIcon fontSize="small" />}
        >
          <Typography
            color="inherit"
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            onClick={() => setCurrentArea("fullSite")}
          >
            NAMI - Covington
          </Typography>
        </Breadcrumbs>
      )}

      {currentArea === "gl01tl04" && (
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<ChevronRightIcon fontSize="small" />}
        >
          <Typography
            color="inherit"
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            onClick={() => setCurrentArea("fullSite")}
          >
            NAMI - Covington
          </Typography>
          <Typography
            color="inherit"
            sx={{ cursor: "pointer", fontWeight: "bold" }}
          >
            GL01 TL04
          </Typography>
        </Breadcrumbs>
      )}
      {currentArea === "tl01cl09" && (
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<ChevronRightIcon fontSize="small" />}
        >
          <Typography
            color="inherit"
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            onClick={() => setCurrentArea("fullSite")}
          >
            NAMI - Covington
          </Typography>
          <Typography
            color="inherit"
            sx={{ cursor: "pointer", fontWeight: "bold" }}
          >
            TL01 CL09
          </Typography>
          {currentSubArea === "square1" && (
            <Typography
              color="inherit"
              sx={{ cursor: "pointer", fontWeight: "bold" }}
            >
              Square 1
            </Typography>
          )}
          {currentSubArea === "square2" && (
            <Typography
              color="inherit"
              sx={{ cursor: "pointer", fontWeight: "bold" }}
            >
              Square 2
            </Typography>
          )}
          {currentSubArea === "square3" && (
            <Typography
              color="inherit"
              sx={{ cursor: "pointer", fontWeight: "bold" }}
            >
              Square 3
            </Typography>
          )}
          {currentSubArea === "square4" && (
            <Typography
              color="inherit"
              sx={{ cursor: "pointer", fontWeight: "bold" }}
            >
              Square 4
            </Typography>
          )}
          {currentSubArea === "square5" && (
            <Typography
              color="inherit"
              sx={{ cursor: "pointer", fontWeight: "bold" }}
            >
              Square 5
            </Typography>
          )}
          {currentSubArea === "square6" && (
            <Typography
              color="inherit"
              sx={{ cursor: "pointer", fontWeight: "bold" }}
            >
              Square 6
            </Typography>
          )}
        </Breadcrumbs>
      )}
      {currentArea === "fullSubAreaCP" && (
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<ChevronRightIcon fontSize="small" />}
        >
          <Typography
            color="inherit"
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            onClick={() => setCurrentArea("fullSite")}
          >
            NAMI - Covington
          </Typography>
          <Typography
            color="inherit"
            sx={{ cursor: "pointer", fontWeight: "bold" }}
          >
            Full Sub Area CP
          </Typography>
        </Breadcrumbs>
      )}
    </Box>
  );
}

export default BreadCrumbs;
