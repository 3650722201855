type LatLngTuple = [number, number];

interface CPMaintenanceSquare {
    id: string;
    bounds: [LatLngTuple, LatLngTuple]
  }

  const CPMaintenanceSquares2ndFloor: CPMaintenanceSquare[] = [
    // Top Row, left to right
      { id: "CP25Maint", bounds: [[51.49565, -0.07107], [51.49528, -0.07141]] }, 
      { id: "CP25Maint", bounds: [[51.49565, -0.07098], [51.49528, -0.07064]] }, 
      { id: "CP25Maint", bounds: [[51.49565, -0.07048], [51.49528, -0.07015]] }, 
      { id: "CP25Maint", bounds: [[51.49565, -0.0701], [51.49528, -0.06977]] }, 
      { id: "CP25Maint", bounds: [[51.49565, -0.06963], [51.49528, -0.0693]] }, 
      { id: "CP25Maint", bounds: [[51.49565, -0.06924], [51.49528, -0.06891]] }, 
      { id: "CP25Maint", bounds: [[51.49565, -0.06874], [51.49528, -0.06841]] }, 
      { id: "CP25Maint", bounds: [[51.49565, -0.06835], [51.49528, -0.06802]] }, 
      { id: "CP25Maint", bounds: [[51.49565, -0.0679], [51.49528, -0.06756]] }, 
      { id: "CP25Maint", bounds: [[51.49565, -0.06751], [51.49528, -0.06717]] }, 
      { id: "CP25Maint", bounds: [[51.49565, -0.06702], [51.49528, -0.06669]] }, 
      { id: "CP25Maint", bounds: [[51.49565, -0.06663], [51.49528, -0.0663]] }, 
    
      // Bottom Row, left to right
      { id: "CP25Maint", bounds: [[51.4944, -0.07102], [51.49409, -0.07136]] }, 
      { id: "CP25Maint", bounds: [[51.4944, -0.070964], [51.49409, -0.07063]] }, 
      { id: "CP25Maint", bounds: [[51.4944, -0.0705], [51.49409, -0.07017]] }, 
      { id: "CP25Maint", bounds: [[51.4944, -0.070115], [51.49409, -0.06978]] }, 
      { id: "CP25Maint", bounds: [[51.4944, -0.06962], [51.49409, -0.06929]] }, 
      { id: "CP25Maint", bounds: [[51.4944, -0.069233], [51.49409, -0.0689]] }, 
      { id: "CP25Maint", bounds: [[51.4944, -0.06875], [51.49409, -0.06842]] }, 
      { id: "CP25Maint", bounds: [[51.4944, -0.06836], [51.49409, -0.06803]] }, 
      { id: "CP25Maint", bounds: [[51.4944, -0.06791], [51.49409, -0.06758]] }, 
      { id: "CP25Maint", bounds: [[51.4944, -0.06747], [51.49409, -0.067135]] }, 
      { id: "CP25Maint", bounds: [[51.4944, -0.06705], [51.49409, -0.06672]] }, 
      { id: "CP25Maint", bounds: [[51.4944, -0.0666], [51.49409, -0.06627]] }, 
      ]

  export default CPMaintenanceSquares2ndFloor