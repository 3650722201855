import {
  Box,
  Button,
  Card,
  CardContent,
  List,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";
import NotificationCard from "./NotificationCard";

const cardData = [
  {
    date: "Monday, 2:05pm, 06/01/24",
    info: "Temperature Threshold Cure Press Area",
    alertColor: "red",
  },
  {
    date: "Monday, 4:01pm, 06/01/24",
    info: "Threshold ALERT. TLO01 Maintentance % for Grinder crossed 20%",
    alertColor: "green",
  },
  {
    date: "Sunday, 2:01pm, 06/01/24",
    info: "Weekly Summary Report Now Available",
    alertColor: "orange",
  },
  {
    date: "Monday, 2:01pm, 06/01/24",
    info: "CP Maintenance % increase week over week",
    alertColor: "green",
  },
];

function NotificationsTab() {
  const [selectedTab, setSelectedTab] = useState<string | null>(
    "environmental"
  );
  const [numberOfCards, setNumberOfCards] = useState(cardData.length);

  const handleTabSelect = (
    event: React.MouseEvent<HTMLElement>,
    newTab: string | null
  ) => {
    setSelectedTab(newTab);
  };

  return (
    <Stack sx={{ height: "100vh" }}>
      <Box sx={{ display: "flex", flexDirection: "column", marginTop: "3%" }}>
        {cardData.map((card) => (
          <NotificationCard
            card={card}
            numberOfCards={numberOfCards}
            setNumberOfCards={setNumberOfCards}
          />
        ))}
      </Box>
    </Stack>
  );
}

export default NotificationsTab;
