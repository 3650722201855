import { Box } from "@mui/material";
import NotificationCard from "../components/NotificationCard";

const mockData = [
  {
    category: "Forklifts",
    status: "Not Started",
    description:
      "Forklifts driven by Shridhar are recording the most interactions at 42 per day (over last week). Other top drivers are averaging 26 interactions per day.",
    aiRecommendedAction:
      "Talk to Shridhar about not driving so close to workers.",
    statusColor: "red",
    date: "Wednesday, 12:45pm, 08/10/24",
  },
  {
    category: "Forklifts Efficiency",
    status: "Not Started",
    description:
      "Forklifts 2, 4, and 6 were not used today. In comparison, forklifts 1, 3 and 5 were all used more than 75% of the shift.",
    aiRecommendedAction:
      "Determine if something is wrong with forklifts 2, 4, and 6.",
    statusColor: "gray",
    date: "Wednesday, 12:15pm, 08/10/24",
  },
  {
    category: "Forklifts",
    status: "Not Started",
    description:
      "90% of all forklift interactions today occurred in area TL01. Top interactors were Imtenon (driver) and Cody (pedestrian) at 40% yesterday.",
    aiRecommendedAction:
      "Evaluate the area and determine if additional safety features are required.",
    statusColor: "orange",
    date: "Tuesday, 4:45pm, 08/09/24",
  },
  {
    category: "Forklifts",
    status: "Not Started",
    description:
      "Interactions in Area TL08 have been very close on average (9ft).",
    aiRecommendedAction:
      "Evaluate the area and determine if additional safety features are required.",
    statusColor: "red",
    date: "Tuesday, 2:46pm, 08/09/24",
  },
  {
    category: "Forklifts",
    status: "Not Started",
    description:
      "Forklift #2 has not been used for the past 7 days and is located in the charging area.",
    aiRecommendedAction:
      "Check to see if something is wrong with this forklift.",
    statusColor: "gray",
    date: "Tuesday, 10:45am, 08/09/24",
  },
  {
    category: "Forklifts",
    status: "Not Started",
    description:
      "Forklift #5 has been unattended for 6 hours outside of charging area.",
    aiRecommendedAction:
      "Have someone return this forklift to the charging station.",
    statusColor: "orange",
    date: "Tuesday, 8:23am, 08/09/24",
  },
];

function Forklifts() {
  return (
    <Box>
      {mockData.map((cardData, index) => (
        <NotificationCard key={index} cardData={cardData} />
      ))}
    </Box>
  );
}

export default Forklifts;
