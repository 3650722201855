import { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Typography, Paper } from "@mui/material";
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

interface ChartOneProps {
  currentSubArea: string | null;
}

const ChartOne = ({ currentSubArea }: ChartOneProps) => {
  const [data, setData] = useState<{ time: string, employees: number }[]>([]);

  const generate24Hours = () => {
    const hoursArray = [];
    for (let i = 0; i < 24; i++) {
      const time = `${i}:00`;
      hoursArray.push({ time, employees: 0 });
    }
    return hoursArray;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/zone-occupation-today-subArea`, 
          {
          params: { currentSubArea },
        });

        const apiData = response.data; 
        console.log(apiData)

        const fullDayData = generate24Hours().map((hour) => {
          const hourData = apiData.find((entry: { time: string }) => entry.time === hour.time);
          return hourData ? hourData : hour; 
        });

        setData(fullDayData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [currentSubArea]);

  return (
    <Paper elevation={3} sx={{ padding: 3, marginTop: 3 }}>
      <Typography variant="h6" gutterBottom fontWeight={"bold"} fontSize={"15px"} textAlign={"left"}>
        Minutes in Zone by Hour Today in {currentSubArea}
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" />
          <YAxis domain={[0, 60]} />
          <Tooltip />
          <Legend />
          <Bar dataKey="minutesSpent" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default ChartOne;
