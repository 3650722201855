import { Box, Container } from "@mui/material";
import TableOne from "./components/TableOne";
import TableTwo from "./components/TableTwo";
import TableThree from "./components/TableThree";

interface MaintenanceProps {
  highlighting: boolean;
  dateRange: [Date | undefined, Date | undefined];
  currentArea: string;
  currentSubArea: string | null;
}

function Maintenance({
  highlighting,
  dateRange,
  currentArea,
  currentSubArea,
}: MaintenanceProps) {
  return (
    <Container>
      <Box>
        <TableOne
          highlighting={highlighting}
          dateRange={dateRange}
          currentArea={currentArea}
          currentSubArea={currentSubArea}
        />
      </Box>
      <Box sx={{ marginTop: "1%" }}>
        <TableTwo
          highlighting={highlighting}
          dateRange={dateRange}
          currentArea={currentArea}
          currentSubArea={currentSubArea}
        />
      </Box>
      <Box sx={{ marginTop: "1%" }}>
        <TableThree
          highlighting={highlighting}
          dateRange={dateRange}
          currentArea={currentArea}
          currentSubArea={currentSubArea}
        />
      </Box>
    </Container>
  );
}
export default Maintenance;
