import Container from "@mui/material/Container";
import DashboardMap from "../components/mapPage/components/DashboardMap";
import MapInteractionBar from "../components/mapPage/components/MapInteractionBar";
import { useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import PersistentDrawerRight from "../components/mapPage/components/persistentDrawerRight/PersistentDrawerRight";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1, 
  width: "100%", 
  transition: theme.transitions.create("margin", {
    easing: open ? theme.transitions.easing.easeOut : theme.transitions.easing.sharp,
    duration: open
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen,
  }),
  marginRight: open ? 0 : `-${drawerWidth}px`, 
  position: "relative",
}));


function MapPage() {
  const [currentArea, setCurrentArea] = useState("fullSite");
  const [showAreaNames, setShowAreaNames] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showZones, setShowZones] = useState<boolean>(false);
  const [currentSubArea, setCurrentSubArea] = useState<string | null>(null);
  const [heatMapEnabled, setHeatMapEnabled] = useState<boolean>(false);
  const [selectedFloor, setSelectedFloor] = useState('1st Floor'); 

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };


  return (
    <Box sx={{  width:'100%', marginLeft:0 }}>
      <CssBaseline />
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerOpen}
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          zIndex: 1300,
          marginRight:'1%',
          marginTop:'.5%',

          ...(drawerOpen && { display: "none" }),
        }}
      >
        {/* <MenuIcon /> */}
        <ArrowBackIosNewIcon sx={{height:'16px', width:'16px', marginTop:'6px', color:'gray'}} />
      </IconButton>
      
      <Main open={drawerOpen} >
            <MapInteractionBar
              setCurrentArea={setCurrentArea}
              currentArea={currentArea}
              setShowAreaNames={setShowAreaNames}
              showAreaNames={showAreaNames}
              setShowZones={setShowZones}
              currentSubArea={currentSubArea}
            />
            <DashboardMap
              currentArea={currentArea}
              setCurrentArea={setCurrentArea}
              showAreaNames={showAreaNames}
              showZones={showZones}
              setCurrentSubArea={setCurrentSubArea}
              currentSubArea={currentSubArea}
              heatMapEnabled={heatMapEnabled}
              selectedFloor={selectedFloor}
              setSelectedFloor={setSelectedFloor}
            />
      </Main>
      <PersistentDrawerRight
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        currentSubArea={currentSubArea}
        setCurrentSubArea = {setCurrentSubArea}
        currentArea={currentArea}
        heatMapEnabled= {heatMapEnabled}
        setHeatMapEnabled = {setHeatMapEnabled}
      />
    </Box>
  );
}

export default MapPage;
