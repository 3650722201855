import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Box,
  Typography,
  CircularProgress
} from "@mui/material";
import axios from 'axios'
const apiUrl = process.env.REACT_APP_API_URL;

interface DataRow {
  totalTime: string;
  averageTimeInCP: string;
  percentageInCPZone: number;
  percentageMaintDN: number;
  percentageMaintUP: number;
  machinesCoveredPerHour: number;
  totalVisits: number;
}

interface TableTwoProps {
  highlighting: boolean;
  dateRange: [Date | undefined, Date | undefined];
  currentArea: string;
  selectedEmployee: string;
}

type Order = "asc" | "desc";

// Function to return color based on percentageTimeSpent
const getColorForColumn = (column: keyof DataRow, value: number): string => {
  switch (column) {
    case "percentageInCPZone":
    case "percentageMaintDN":
    case "percentageMaintUP":
      if (value >= 20) return "#0c5720";   // Dark green for the highest values (20%+)
      if (value >= 15) return "#47a02f";   // Strong green (15-19%)
      if (value >= 10) return "#75d62b";   // Medium green (10-14%)
      if (value >= 8) return "#99c93e";    // Light green (8-9%)
      if (value >= 6) return "#b0c93e";    // Green-yellow (6-7%)
      if (value >= 4) return "#cbd934";    // Yellow (4-5%)
      if (value >= 2) return "#d99134";    // Orange (2-3%)
      if (value >= 1) return "#d96b34";    // Dark orange (1-1.9%)
      if (value >= 0.5) return "#d94434";  // Tomato (0.5-0.9%)
      return "#f2f2f2";                    // Default light gray
    default:
      return "#f2f2f2";                    // Default light gray
  }
};

// Sorting helpers
const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = <T,>(
  order: Order,
  orderBy: keyof T
): ((a: T, b: T) => number) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = <T,>(
  array: T[],
  comparator: (a: T, b: T) => number
): T[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

function TableTwo({ highlighting, dateRange, currentArea, selectedEmployee }: TableTwoProps) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof DataRow>("totalTime");
  const [data, setData] = useState<DataRow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleRequestSort = (property: keyof DataRow) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Mock data based on screenshot
  useEffect(() => {
    if (!dateRange[0] || !dateRange[1] || !currentArea || !selectedEmployee) {
      return;
    }
    
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/employee-maintenance-report`, {
            params: {
              startDate: dateRange[0]?.toISOString(),
              endDate: dateRange[1]?.toISOString(),
              employeeName: selectedEmployee
            }
          });
          console.log("Updated data:", response.data);
        
      } catch (error) {
        console.error("Error fetching zone data:", error);
      }
    } 

  fetchData()
    
  }, [dateRange])

  const sortedData = stableSort(data, getComparator(order, orderBy));

  return (
    <>
      <TableContainer component={Paper} style={{ maxHeight: 500, overflow: "auto" }}>
        <Typography
          sx={{
            fontWeight: "bold",
            paddingTop: "1%",
            fontSize: "15px",
            marginLeft: "2%"
          }}
        >
          Maintenance Report
        </Typography>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", padding: "20px" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Table stickyHeader>
            <TableHead sx={{ zIndex: 0 }}>
              <TableRow>
                {[
                  { id: "totalTime", label: "Total Time" },
                  // { id: "averageTimeInCP", label: "Average Time in CP" },
                  { id: "percentageInCPZone", label: "% Time CP Zone" },
                  { id: "percentageMaintDN", label: "% Time Maint DN" },
                  { id: "percentageMaintUP", label: "% Time Maint UP" },
                  // { id: "machinesCoveredPerHour", label: "Machines Covered / Hour" },
                  // { id: "totalVisits", label: "Total Visits" }
                ].map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align="right"
                    sortDirection={orderBy === headCell.id ? order : false}
                    sx={{
                      padding: "2px 8px",
                      overflow: "hidden",
                      fontWeight: "bold",
                      textOverflow: "ellipsis",
                      zIndex: 0
                    }}
                  >
                    <TableSortLabel
                    sx={{}}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleRequestSort(headCell.id as keyof DataRow)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((row, index) => (
                <TableRow key={index} sx={{ height: 24 }}>
                  <TableCell align="right" sx={{ padding: "2px 8px", fontWeight: "bold" }}>
                    {row.totalTime}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: "2px 8px", fontWeight: "bold" }}>
                    {row.averageTimeInCP}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      backgroundColor: highlighting
                        ? getColorForColumn("percentageInCPZone", row.percentageInCPZone)
                        : undefined,
                      padding: "2px 8px",
                      fontWeight: "bold"
                    }}
                  >
                    {row.percentageInCPZone ? `${row.percentageInCPZone}%` : ""}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      backgroundColor: highlighting
                        ? getColorForColumn("percentageMaintDN", row.percentageMaintDN)
                        : undefined,
                      padding: "2px 8px",
                      fontWeight: "bold"
                    }}
                  >
                    {row.percentageMaintDN ? `${row.percentageMaintDN}%` : ""}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      backgroundColor: highlighting
                        ? getColorForColumn("percentageMaintUP", row.percentageMaintUP)
                        : undefined,
                      padding: "2px 8px",
                      fontWeight: "bold"
                    }}
                  >
                    {row.percentageMaintUP ? `${row.percentageMaintUP}%` : ""}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: "2px 8px", fontWeight: "bold" }}>
                    {row.machinesCoveredPerHour}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: "2px 8px", fontWeight: "bold" }}>
                    {row.totalVisits}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  );
}

export default TableTwo;
