import { Box } from "@mui/material"
import ChartOne from "./components/ChartOne"
import TableOne from './components/TableOne'

interface TableOneProps {
    highlighting: boolean;
    dateRange: [Date | undefined, Date | undefined];
    currentArea: string;
    currentSubArea: string | null;
  
  }

function SubZoneDisplay ({highlighting,dateRange,currentArea,currentSubArea}: TableOneProps) {
    return (
        <Box>
        <TableOne highlighting={highlighting}
          dateRange={dateRange}
          currentArea={currentArea}
          currentSubArea={currentSubArea} />
        <ChartOne currentSubArea={currentSubArea}/>
        </Box>
    )
}

export default SubZoneDisplay