import { Box } from "@mui/material";
import NotificationCard from "../components/NotificationCard";

const mockData = [
  {
    category: "Ergonomics",
    status: "Not Started",
    description:
      "New employees (<1 month) are recording 20 less bends per day than tenured employees. Roughly 45% of these are considered risky bends.",
    aiRecommendedAction: "Have new employees see the trainer.",
    statusColor: "orange",
    date: "Wednesday, 12:16pm, 08/10/24",
  },
  {
    category: "Ergonomics",
    status: "Not Started",
    description:
      "This week, mixing workers on average are recording 31 more risky bends per day than Packaging workers (up 140% from last week).",
    aiRecommendedAction:
      "Check to see who is contributing to this high count and consider sending them to see trainer.",
    statusColor: "orange",
    date: "Wednesday, 12:45pm, 08/10/24",
  },
  {
    category: "Efficiency",
    status: "Not Started",
    description:
      "Night shift employees are recording the most wait time compared to other shifts. Workers on the Packaging line are waiting 64% of the time between 3:13am and 5:28am.",
    aiRecommendedAction:
      "Ensure workers are not taking extra long breaks during this time period.",
    statusColor: "orange",
    date: "Tuesday, 4:45pm, 08/09/24",
  },
  {
    category: "Ergonomics",
    status: "Not Started",
    description:
      "Hunter Burgett exceeded his daily twist limit. He exceeded their limit 4:21 minutes into his shift (54%). He usually does not exceed 80% of daily twist limit.",
    aiRecommendedAction:
      "Have Hunter take a break and assign him to less physical work for the rest of the day.",
    statusColor: "red",
    date: "Tuesday, 2:46pm, 08/09/24",
  },
  {
    category: "Ergonomics",
    status: "Not Started",
    description:
      "The workers with the highest MET Score today are: Will St. Mary (packaging), Nick Thilen (packaging), Cody Calhoun (packaging).",
    aiRecommendedAction:
      "Ensure they are taking breaks when needed as their exertion is high today.",
    statusColor: "orange",
    date: "Tuesday, 10:45am, 08/10/24",
  },
  {
    category: "Efficiency",
    status: "Not Started",
    description:
      "William Rose's (mixing) travel time has been 71% today. In comparison, other mixing workers are averaging 13% travel time.",
    aiRecommendedAction:
      "Help William Rose become more efficient while he is working.",
    statusColor: "orange",
    date: "Tuesday, 8:23am, 08/09/24",
  },
];

function Ergonomics() {
  return (
    <Box>
      {mockData.map((cardData, index) => (
        <NotificationCard key={index} cardData={cardData} />
      ))}
    </Box>
  );
}

export default Ergonomics;
