import { Box, Stack } from "@mui/material";
import "./App.css";
import TopBar from "./components/TopBar";
import LeftBar from "./components/LeftBar";
import { useState, useEffect } from "react";
import DataPage from "./pages/DataPage";
import NotificationsPage from "./pages/NotificationsPage";
import Login from "./pages/Login";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import MapPage from "./pages/MapPage";
import axios from "axios";
import TestPage from "./pages/TestPage";

// Authentication check function
const isAuthenticated = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      let response = await axios.get(
        "http://localhost:4000/authentication-check",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.authenticationStatus;
    } catch (error) {
      console.error("Error during authentication check", error);
      return false;
    }
  } else {
    return false;
  }
};

interface ProtectedRouteProps {
  children: JSX.Element;
  isAuth: boolean | undefined;
}

// Wrapper for protected routes
const ProtectedRoute = ({ children, isAuth }: ProtectedRouteProps) => {
  if (isAuth === undefined) return <div>Loading...</div>;

  return isAuth ? children : <Navigate to="/login" />;
};

function App() {
  const [currentPage, setCurrentPage] = useState("Map");
  const [isAuth, setIsAuth] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const checkAuth = async () => {
      const authStatus = await isAuthenticated();
      setIsAuth(authStatus);
    };

    checkAuth();
  }, []);

  return (
    <Router>
        <TopBar isAuth={isAuth} setIsAuth={setIsAuth} />
        <Stack  direction="row"  justifyContent="space-between" >
          <LeftBar
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isAuth={isAuth}
          />
          <Box flex={10} >
            <Routes>
              <Route path="/login" element={<Login setIsAuth={setIsAuth} />} />
              <Route
                path="/map"
                element={
                  <ProtectedRoute isAuth={isAuth}>
                    <MapPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute isAuth={isAuth}>
                    <DataPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/notifications"
                element={
                  <ProtectedRoute isAuth={isAuth}>
                    <NotificationsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/testpage"
                element={
                  <ProtectedRoute isAuth={isAuth}>
                    <TestPage />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<Navigate to="/map" />} />
            </Routes>
          </Box>
        </Stack>
    </Router>
  );
}

export default App;
