import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  TextField,
  Button,
} from "@mui/material";
import Logo from "../images/modjoul-logo-horizontal-1 color.png";
import { useNavigate, Navigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";

interface TopBarProps {
  isAuth: boolean | undefined;
  setIsAuth: (auth: boolean | undefined) => void;
}

function TopBar({ isAuth, setIsAuth }: TopBarProps) {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuth(false);
    navigate("/login");
  };
  return (
    <AppBar
      position="sticky"
      style={{ width: "100%", backgroundColor: "white" }}
    >
      <Toolbar>
        <img
          src={Logo}
          alt="Modjoul Logo"
          style={{ width: "240px", height: "45px", marginRight: "10px" }}
        />
        <Box
          sx={{ border: "solid" }}
          width={"100%"}
          display={"flex"}
          justifyContent={"space-evenly"}
        >
          {isAuth && (
            <TextField
              sx={{ width: "22%" }}
              id="outlined-basic"
              label="Search"
              variant="outlined"
              size="small"
            />
          )}

          {isAuth && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<LogoutIcon />}
              onClick={handleLogout}
              sx={{
                backgroundColor: "#1976d2",
                color: "white",
                textTransform: "none",
                padding: "6px 16px",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              }}
            >
              Logout
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
