import React, { useRef } from "react";
import * as QuickSightEmbedding from "amazon-quicksight-embedding-sdk";

interface QuickSightDashboardProps {
  embedUrl: string;
}

const QuickSightDashboard = ({ embedUrl }: QuickSightDashboardProps) => {
  const hasEmbedded = useRef(false);

  const embedDashboard = async () => {
    try {
      const embeddingContext =
        await QuickSightEmbedding.createEmbeddingContext();

      const frameOptions = {
        url: embedUrl,
        container: "#dashboard-container",
        height: "800px",
        width: "100%",
      };

      await embeddingContext.embedDashboard(frameOptions);
    } catch (error) {
      console.error("Failed to embed dashboard:", error);
    }
  };

  if (!hasEmbedded.current) {
    embedDashboard();
    hasEmbedded.current = true;
  }

  return <div id="dashboard-container"></div>;
};

export default QuickSightDashboard;
