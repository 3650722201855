import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Box,
  Typography,
} from "@mui/material";

// Define the data type
interface DataRow {
  role: string;
  workZone: number;
  maintZone: number;
  otherZone: number;
  wearablePercentage: number;
}

interface TableTwoProps {
  highlighting: boolean;
  dateRange: [Date | undefined, Date | undefined];
  currentArea: string;
  currentSubArea: string | null;
}

const data: DataRow[] = [
  {
    role: "Employee 1",
    workZone: 73,
    maintZone: 20,
    otherZone: 7,
    wearablePercentage: 79,
  },
  {
    role: "Employee 17",
    workZone: 73,
    maintZone: 5,
    otherZone: 22,
    wearablePercentage: 79,
  },
  {
    role: "Employee 11",
    workZone: 66,
    maintZone: 23,
    otherZone: 11,
    wearablePercentage: 75,
  },
  {
    role: "Employee 16",
    workZone: 61,
    maintZone: 20,
    otherZone: 19,
    wearablePercentage: 59,
  },
  {
    role: "Employee 9",
    workZone: 59,
    maintZone: 23,
    otherZone: 18,
    wearablePercentage: 64,
  },
  {
    role: "Employee 2",
    workZone: 57,
    maintZone: 18,
    otherZone: 25,
    wearablePercentage: 55,
  },
  {
    role: "Employee 6",
    workZone: 57,
    maintZone: 25,
    otherZone: 18,
    wearablePercentage: 77,
  },
  {
    role: "Employee 14",
    workZone: 57,
    maintZone: 23,
    otherZone: 20,
    wearablePercentage: 52,
  },
  {
    role: "Employee 8",
    workZone: 54,
    maintZone: 24,
    otherZone: 22,
    wearablePercentage: 41,
  },
  {
    role: "Employee 5",
    workZone: 52,
    maintZone: 19,
    otherZone: 29,
    wearablePercentage: 84,
  },
];

const getColorForColumn = (column: keyof DataRow, value: number): string => {
  switch (column) {
    case "workZone":
      if (value >= 70) return "#9cc593"; // Light green
      if (value >= 60) return "#e5e579"; // Yellow-green
      if (value >= 50) return "#eedc6b"; // Light yellow
      if (value >= 40) return "#f2b476"; // Light orange
      if (value >= 30) return "#ed7668"; // Dark red
      return "#f2f2f2"; // Default light gray

    case "maintZone":
      if (value >= 5 && value < 10) return "#9cc593"; // Light green
      if (value >= 10 && value < 17) return "#e5e579"; // Yellow-green
      if (value >= 17 && value < 21) return "#eedc6b"; // Light yellow
      if (value >= 21 && value <= 23) return "#f2b476"; // Light orange
      if (value >= 23) return "#ed7668"; // Dark red
      return "#f2f2f2"; // Default light gray

    case "otherZone":
      if (value >= 47) return "#ed7668"; // Dark red
      if (value >= 25) return "#f2b476"; // Light orange
      if (value >= 15) return "#eedc6b"; // Light yellow
      if (value >= 5) return "#9cc593"; // Light green
      return "#9cc593"; // Light green

    case "wearablePercentage":
      if (value >= 60 && value < 70) return "#ed7668"; // Dark red
      if (value >= 70 && value < 80) return "#f2b476"; // Light orange
      if (value >= 80 && value < 90) return "#eedc6b"; // Light yellow
      if (value >= 90 && value < 93) return "#e5e579"; // Yellow-green
      return "#9cc593"; // Light green

    default:
      return "#f2f2f2"; // Default light gray
  }
};

type Order = "asc" | "desc";

const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = <T,>(
  order: Order,
  orderBy: keyof T
): ((a: T, b: T) => number) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = <T,>(
  array: T[],
  comparator: (a: T, b: T) => number
): T[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

function TableTwo({
  highlighting,
  dateRange,
  currentArea,
  currentSubArea,
}: TableTwoProps) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof DataRow>("role");

  const handleRequestSort = (property: keyof DataRow) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = stableSort(data, getComparator(order, orderBy));

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontWeight: "bold" }}>
          Job Role Maintenance Work Zone {currentArea}{" "}
          {currentArea && currentArea != "fullSite" && currentSubArea}
        </Typography>
      </Box>
      <TableContainer
        component={Paper}
        style={{ maxHeight: 325, overflow: "auto" }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {[
                { id: "role", label: "" },
                { id: "workZone", label: "Work Zone" },
                { id: "maintZone", label: "Maint Zone" },
                { id: "otherZone", label: "Other Zone" },
                { id: "wearablePercentage", label: "Wearable %" },
              ].map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align="right"
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={{
                    padding: "2px 8px",
                    // whiteSpace: "nowrap"
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: "bold",
                  }} // Reduced padding and nowrap
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={() =>
                      handleRequestSort(headCell.id as keyof DataRow)
                    }
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, index) => (
              <TableRow key={index} sx={{ height: 24 }}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    padding: "2px 8px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: "bold",
                  }} // Reduced padding and nowrap
                >
                  {row.role}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn("workZone", row.workZone)
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                  }}
                >
                  {row.workZone}%
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn("maintZone", row.maintZone)
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                  }}
                >
                  {row.maintZone}%
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn("otherZone", row.otherZone)
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                  }}
                >
                  {row.otherZone}%
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn(
                          "wearablePercentage",
                          row.wearablePercentage
                        )
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                  }}
                >
                  {row.wearablePercentage}%
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default TableTwo;
