import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useState, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Checkbox,
  Link,
  ListItemText,
  OutlinedInput,
  Typography,
} from "@mui/material";
import BreadCrumbs from "./BreadCrumbs.";

interface MapInteractionBarProps {
  setCurrentArea: (area: string) => void;
  currentArea: string;
  setShowAreaNames: (bool: boolean) => void;
  showAreaNames: boolean;
  setShowZones: (bool: boolean) => void;
  currentSubArea: string | null;
}

const toggles = ["Area Names", "Zones of Interest", "Forklifts", "Alerts"];

function MapInteractionBar({
  setCurrentArea,
  currentArea,
  setShowAreaNames,
  showAreaNames,
  setShowZones,
  currentSubArea,
}: MapInteractionBarProps) {
  const [toggleList, setToggleList] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent) => {
    setCurrentArea(event.target.value as string);
  };

  const handleSecondChange = (event: SelectChangeEvent<typeof toggleList>) => {
    const {
      target: { value },
    } = event;

    setToggleList(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    if (toggleList.includes("Area Names")) {
      setShowAreaNames(true);
    } else {
      setShowAreaNames(false);
    }

    if (toggleList.includes("Zones of Interest")) {
      setShowZones(true);
    } else {
      setShowZones(false);
    }
  }, [
    toggleList,
    setShowAreaNames,
    currentArea,
    setShowAreaNames,
    showAreaNames,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between", // Pushes breadcrumb to left and toggles to right
        alignItems: "center", // Centers items vertically
        padding: "10px",
        width: "100%",
        borderBottom: "1px solid #ccc", 
      }}
    >
      {/* Left-aligned breadcrumb */}
      <BreadCrumbs
        currentArea={currentArea}
        setCurrentArea={setCurrentArea}
        currentSubArea={currentSubArea}
      />

      {/* Right-aligned toggles */}
      <FormControl sx={{ width: "300px",marginRight:'3%' }}>
        <InputLabel id="demo-multiple-checkbox-label">Toggles</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={toggleList}
          onChange={handleSecondChange}
          input={<OutlinedInput label="Toggles" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {toggles.map((toggle) => (
            <MenuItem key={toggle} value={toggle}>
              <Checkbox checked={toggleList.indexOf(toggle) > -1} />
              <ListItemText primary={toggle} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
export default MapInteractionBar;
