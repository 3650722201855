import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Box,
  Typography,
} from "@mui/material";

// Define the data type
interface DataRow {
  role: string;
  lumbarScore: number;
  bendsPerHour: number;
  bendsOver60PerHour: number;
  twistsPerHour: number;
}

// Sample data from the image
const data: DataRow[] = [
  {
    role: "Employee 9",
    lumbarScore: 421,
    bendsPerHour: 91,
    bendsOver60PerHour: 9,
    twistsPerHour: 36,
  },
  {
    role: "Employee 16",
    lumbarScore: 415,
    bendsPerHour: 81,
    bendsOver60PerHour: 22,
    twistsPerHour: 10,
  },
  {
    role: "Employee 17",
    lumbarScore: 397,
    bendsPerHour: 84,
    bendsOver60PerHour: 34,
    twistsPerHour: 40,
  },
  {
    role: "Employee 4",
    lumbarScore: 388,
    bendsPerHour: 88,
    bendsOver60PerHour: 49,
    twistsPerHour: 36,
  },
  {
    role: "Employee 21",
    lumbarScore: 357,
    bendsPerHour: 81,
    bendsOver60PerHour: 10,
    twistsPerHour: 40,
  },
  {
    role: "Employee 18",
    lumbarScore: 354,
    bendsPerHour: 90,
    bendsOver60PerHour: 52,
    twistsPerHour: 49,
  },
  {
    role: "Employee 22",
    lumbarScore: 353,
    bendsPerHour: 83,
    bendsOver60PerHour: 49,
    twistsPerHour: 47,
  },
  {
    role: "Employee 4",
    lumbarScore: 346,
    bendsPerHour: 89,
    bendsOver60PerHour: 4,
    twistsPerHour: 6,
  },
  {
    role: "Employee 3",
    lumbarScore: 339,
    bendsPerHour: 79,
    bendsOver60PerHour: 4,
    twistsPerHour: 38,
  },
  {
    role: "Employee 12",
    lumbarScore: 338,
    bendsPerHour: 85,
    bendsOver60PerHour: 24,
    twistsPerHour: 38,
  },
  {
    role: "Employee 12",
    lumbarScore: 338,
    bendsPerHour: 85,
    bendsOver60PerHour: 24,
    twistsPerHour: 38,
  },
  {
    role: "Employee 12",
    lumbarScore: 338,
    bendsPerHour: 85,
    bendsOver60PerHour: 24,
    twistsPerHour: 38,
  },
  {
    role: "Employee 12",
    lumbarScore: 338,
    bendsPerHour: 85,
    bendsOver60PerHour: 24,
    twistsPerHour: 38,
  },
  {
    role: "Employee 12",
    lumbarScore: 338,
    bendsPerHour: 85,
    bendsOver60PerHour: 24,
    twistsPerHour: 38,
  },
  {
    role: "Employee 12",
    lumbarScore: 338,
    bendsPerHour: 85,
    bendsOver60PerHour: 24,
    twistsPerHour: 38,
  },
];

const getColorForColumn = (column: keyof DataRow, value: number): string => {
  switch (column) {
    case "lumbarScore":
      if (value >= 400) return "#ed7668"; // Dark red
      if (value >= 380) return "#f2b476"; // Light orange
      if (value >= 322) return "#eedc6b"; // Light yellow
      if (value >= 280) return "#e5e579"; // Yellow-green
      if (value >= 250) return "#9cc593"; // Light green
      return "#f2f2f2"; // Default light gray

    case "bendsPerHour":
      if (value >= 90) return "#ed7668"; // Dark red
      if (value >= 85) return "#f2b476"; // Light orange
      if (value >= 84) return "#eedc6b"; // Light yellow
      if (value >= 75) return "#e5e579"; // Yellow-green
      if (value >= 70) return "#9cc593"; // Light green
      return "#f2f2f2"; // Default light gray

    case "bendsOver60PerHour":
      if (value >= 40) return "#ed7668"; // Dark red
      if (value >= 25) return "#f2b476"; // Light orange
      if (value >= 15) return "#eedc6b"; // Light yellow
      if (value >= 5) return "#9cc593"; // Light green
      return "#9cc593"; // Light green

    case "twistsPerHour":
      if (value >= 43) return "#ed7668"; // Dark red
      if (value >= 33) return "#f2b476"; // Light orange
      if (value >= 23) return "#eedc6b"; // Light yellow
      if (value >= 13) return "#e5e579"; // Yellow-green
      return "#9cc593"; // Light green

    default:
      return "#f2f2f2"; // Default light gray
  }
};

type Order = "asc" | "desc";

const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = <T,>(
  order: Order,
  orderBy: keyof T
): ((a: T, b: T) => number) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = <T,>(
  array: T[],
  comparator: (a: T, b: T) => number
): T[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

interface TableTwoProps {
  highlighting: boolean;
  currentArea: string;
  currentSubArea: string | null;
}

function TableTwo({
  highlighting,
  currentArea,
  currentSubArea,
}: TableTwoProps) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof DataRow>("role");

  const handleRequestSort = (property: keyof DataRow) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = stableSort(data, getComparator(order, orderBy));

  const maxLumbarScore = Math.max(...data.map((d) => d.lumbarScore));
  const maxBendsPerHour = Math.max(...data.map((d) => d.bendsPerHour));
  const maxBendsOver60PerHour = Math.max(
    ...data.map((d) => d.bendsOver60PerHour)
  );
  const maxTwistsPerHour = Math.max(...data.map((d) => d.twistsPerHour));

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontWeight: "bold" }}>
          Employee Ergonomics Work Zone {currentArea}{" "}
          {currentArea && currentArea != "fullSite" && currentSubArea}
        </Typography>
      </Box>
      <TableContainer
        component={Paper}
        style={{ maxHeight: 325, overflow: "auto" }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {[
                { id: "role", label: "" },
                { id: "lumbarScore", label: "Lumbar Score" },
                { id: "bendsPerHour", label: "Bends Per Hour" },
                { id: "bendsOver60PerHour", label: "Bends>60 Per Hour" },
                { id: "twistsPerHour", label: "Twists Per Hour" },
              ].map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align="center"
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={{
                    padding: "2px 8px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    fontWeight: "bold",
                    textOverflow: "ellipsis",
                    // fontSize: "0.75rem", // Smaller font size
                  }}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={() =>
                      handleRequestSort(headCell.id as keyof DataRow)
                    }
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, index) => (
              <TableRow key={index} sx={{ height: 24 }}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    padding: "2px 8px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: "bold",
                    // fontSize: "0.75rem", // Smaller font size
                  }} // Reduced padding and nowrap
                >
                  {row.role}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn("lumbarScore", row.lumbarScore)
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                    // fontSize: "0.75rem", // Smaller font size
                  }}
                >
                  {row.lumbarScore}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn("bendsPerHour", row.bendsPerHour)
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                    // fontSize: "0.75rem", // Smaller font size
                  }}
                >
                  {row.bendsPerHour}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn(
                          "bendsOver60PerHour",
                          row.bendsOver60PerHour
                        )
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                    // fontSize: "0.75rem", // Smaller font size
                  }}
                >
                  {row.bendsOver60PerHour}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn("twistsPerHour", row.twistsPerHour)
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                    // fontSize: "0.75rem", // Smaller font size
                  }}
                >
                  {row.twistsPerHour}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default TableTwo;
