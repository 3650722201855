import { Box, Grid } from "@mui/material";
import TableOne from "./components/TableOne";
import TableTwo from "./components/TableTwo";
import TableThree from "./components/TableThree";
import TableFour from "./components/TableFour";
import ChartOne from ".//components/ChartOne";
import TableFive from "./components/TableFive";

interface MaintenanceProps {
    highlighting: boolean;
    dateRange: [Date | undefined, Date | undefined];
    currentArea: string;
    currentSubArea: string | null;
}
function Maintenance({highlighting,dateRange,currentArea,currentSubArea}:MaintenanceProps) {
  return (
      <Grid container spacing={2}>
        {/* <Grid  item xs={6}>
      <TableOne
      highlighting={highlighting}
      dateRange={dateRange}
          currentArea={currentArea}
          currentSubArea={currentSubArea} />

        </Grid> */}
        {/* <Grid item xs={6}>
      <TableTwo  highlighting={highlighting}
      dateRange={dateRange}
          currentArea={currentArea}
          currentSubArea={currentSubArea} />
          </Grid> */}
          <Grid item xs={6}>
            <TableThree highlighting={highlighting}
      dateRange={dateRange}
          currentArea={currentArea}
          currentSubArea={currentSubArea} />
          </Grid>
          <Grid item xs={6}>
            <TableFour highlighting={highlighting}
      dateRange={dateRange}
          currentArea={currentArea}
          currentSubArea={currentSubArea} />
          </Grid>
          <Grid item xs={6}>
            <TableFive highlighting={highlighting}
      dateRange={dateRange}
          currentArea={currentArea}
          currentSubArea={currentSubArea} />
          </Grid>
          <Grid item xs={12}>
            <ChartOne highlighting={highlighting}
      dateRange={dateRange}
          currentArea={currentArea}
          currentSubArea={currentSubArea} />
          </Grid>
      </Grid>
  )
}

export default Maintenance