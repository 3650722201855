import { Box, Container } from "@mui/material";
import TableOne from "./components/TableOne";
import TableTwo from "./components/TableTwo";

interface ForkliftsProps {
  dateRange: [Date | undefined, Date | undefined];
  highlighting: boolean;
  currentArea: string;
  currentSubArea: string | null;
}

function Forklifts({
  dateRange,
  highlighting,
  currentArea,
  currentSubArea,
}: ForkliftsProps) {
  return (
    <Container>
      <Box>
        <TableOne
          highlighting={highlighting}
          dateRange={dateRange}
          currentArea={currentArea}
          currentSubArea={currentSubArea}
        />
      </Box>
      <Box sx={{ marginTop: "1%" }}>
        <TableTwo
          highlighting={highlighting}
          dateRange={dateRange}
          currentArea={currentArea}
          currentSubArea={currentSubArea}
        />
      </Box>
    </Container>
  );
}

export default Forklifts;
