import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Box,
  Typography,
} from "@mui/material";

// Define the data type
interface DataRow {
  role: string;
  today: number;
  yesterday: number;
  yesterdayTwo: number;
  week: number;
  month: number;
}

const data: DataRow[] = [
  {
    role: "Forklift 1",
    today: 86,
    yesterday: 89,
    yesterdayTwo: 85,
    week: 85,
    month: 93,
  },
  {
    role: "Forklift 2",
    today: 88,
    yesterday: 86,
    yesterdayTwo: 89,
    week: 93,
    month: 93,
  },
  {
    role: "Forklift 3",
    today: 86,
    yesterday: 88,
    yesterdayTwo: 86,
    week: 85,
    month: 86,
  },
  {
    role: "Forklift 4",
    today: 61,
    yesterday: 58,
    yesterdayTwo: 63,
    week: 58,
    month: 58,
  },
  {
    role: "Forklift 5",
    today: 92,
    yesterday: 89,
    yesterdayTwo: 91,
    week: 91,
    month: 90,
  },
  {
    role: "Forklift 6",
    today: 72,
    yesterday: 72,
    yesterdayTwo: 76,
    week: 74,
    month: 72,
  },
  {
    role: "Forklift 7",
    today: 92,
    yesterday: 89,
    yesterdayTwo: 89,
    week: 87,
    month: 87,
  },
  {
    role: "Forklift 8",
    today: 86,
    yesterday: 84,
    yesterdayTwo: 83,
    week: 86,
    month: 86,
  },
  {
    role: "Forklift 9",
    today: 72,
    yesterday: 60,
    yesterdayTwo: 83,
    week: 58,
    month: 66,
  },
  {
    role: "Forklift 10",
    today: 90,
    yesterday: 88,
    yesterdayTwo: 88,
    week: 88,
    month: 89,
  },
  {
    role: "Forklift 11",
    today: 75,
    yesterday: 60,
    yesterdayTwo: 83,
    week: 58,
    month: 66,
  },
];

const getColorForColumn = (column: keyof DataRow, value: number): string => {
  switch (column) {
    case "today":
      if (value >= 90) return "#9cc593"; // Light green
      if (value >= 80 && value < 90) return "#e5e579"; // Yellow-green
      if (value >= 70 && value < 80) return "#eedc6b"; // Light yellow
      if (value >= 60 && value < 70) return "#f2b476"; // Light orange
      if (value >= 50 && value < 60) return "#ed7668"; // Dark red
      return "#f2f2f2"; // Default light gray

    case "yesterday":
      if (value >= 85) return "#9cc593"; // Light green
      if (value >= 80 && value < 85) return "#e5e579"; // Yellow-green
      if (value >= 70 && value < 80) return "#eedc6b"; // Light yellow
      if (value >= 60 && value <= 70) return "#f2b476"; // Light orange
      if (value >= 25) return "#ed7668"; // Dark red
      return "#f2f2f2"; // Default light gray

    case "yesterdayTwo":
      if (value >= 85) return "#9cc593"; // Light green
      if (value >= 80 && value < 85) return "#e5e579"; // Yellow-green
      if (value >= 70 && value < 80) return "#eedc6b"; // Light yellow
      if (value >= 60 && value <= 70) return "#f2b476"; // Light orange
      if (value >= 25) return "#ed7668"; // Dark red
      return "#f2f2f2"; // Default light gray

    case "week":
      if (value >= 85) return "#9cc593"; // Light green
      if (value >= 80 && value < 85) return "#e5e579"; // Yellow-green
      if (value >= 70 && value < 80) return "#eedc6b"; // Light yellow
      if (value >= 60 && value <= 70) return "#f2b476"; // Light orange
      if (value >= 25) return "#ed7668"; // Dark red
      return "#f2f2f2"; // Default light gray

    case "month":
      if (value >= 85) return "#9cc593"; // Light green
      if (value >= 80 && value < 85) return "#e5e579"; // Yellow-green
      if (value >= 70 && value < 80) return "#eedc6b"; // Light yellow
      if (value >= 60 && value <= 70) return "#f2b476"; // Light orange
      if (value >= 25) return "#ed7668"; // Dark red
      return "#f2f2f2"; // Default light gray

    default:
      return "#f2f2f2"; // Default light gray
  }
};

type Order = "asc" | "desc";

const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = <T,>(
  order: Order,
  orderBy: keyof T
): ((a: T, b: T) => number) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = <T,>(
  array: T[],
  comparator: (a: T, b: T) => number
): T[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

interface TableOneProps {
  highlighting: boolean;
  dateRange: [Date | undefined, Date | undefined];
  currentArea: string;
  currentSubArea: string | null;
}

function TableOne({
  highlighting,
  dateRange,
  currentArea,
  currentSubArea,
}: TableOneProps) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof DataRow>("role");

  const handleRequestSort = (property: keyof DataRow) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = stableSort(data, getComparator(order, orderBy));

  const maxtoday = Math.max(...data.map((d) => d.today));
  const maxyesterday = Math.max(...data.map((d) => d.yesterday));
  const maxyesterdayTwo = Math.max(...data.map((d) => d.yesterdayTwo));
  const maxweek = Math.max(...data.map((d) => d.week));
  const maxMonth = Math.max(...data.map((d) => d.month));

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontWeight: "bold" }}>
          Forklift Utilization {currentArea}{" "}
          {currentArea && currentArea != "fullSite" && currentSubArea}
        </Typography>
      </Box>
      <TableContainer
        component={Paper}
        style={{ maxHeight: 325, overflow: "auto" }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {[
                { id: "role", label: "" },
                { id: "today", label: "Today" },
                { id: "yesterday", label: "Yesterday" },
                { id: "yesterdayTwo", label: "Yesterday Two" },
                { id: "week", label: "Week" },
                { id: "month", label: "Month" },
              ].map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align="center"
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={{
                    padding: "2px 8px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    fontWeight: "bold",
                    textOverflow: "ellipsis",
                    // fontSize: "0.75rem", // Smaller font size
                  }}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={() =>
                      handleRequestSort(headCell.id as keyof DataRow)
                    }
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, index) => (
              <TableRow key={index} sx={{ height: 24 }}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    padding: "2px 8px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: "bold",
                    // fontSize: "0.75rem", // Smaller font size
                  }} // Reduced padding and nowrap
                >
                  {row.role}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn("today", row.today)
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                    // fontSize: "0.75rem", // Smaller font size
                  }}
                >
                  {row.today}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn("yesterday", row.yesterday)
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                    // fontSize: "0.75rem", // Smaller font size
                  }}
                >
                  {row.yesterday}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn("yesterdayTwo", row.yesterdayTwo)
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                    // fontSize: "0.75rem", // Smaller font size
                  }}
                >
                  {row.yesterdayTwo}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn("week", row.week)
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                    // fontSize: "0.75rem", // Smaller font size
                  }}
                >
                  {row.week}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: highlighting
                      ? getColorForColumn("month", row.month)
                      : undefined,
                    padding: "2px 8px", // Reduced padding
                    fontWeight: "bold",
                    // fontSize: "0.75rem", // Smaller font size
                  }}
                >
                  {row.month}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default TableOne;
