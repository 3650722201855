import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Typography, Paper, Box, CircularProgress } from "@mui/material";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

interface DataItem {
  time: string;
  [zone: string]: number | string; // Dynamic key for zones, can hold numbers
}

interface ChartOneProps {
  highlighting: boolean;
  dateRange: [Date | undefined, Date | undefined];
  currentArea: string;
  selectedEmployee: string;
}

const ChartOne = ({ dateRange, selectedEmployee }: ChartOneProps) => {
  const [data, setData] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!dateRange[0] || !dateRange[1] || !selectedEmployee) {
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/api/cp-employee-zones-summary-barchart`, {
          params: {
            startDate: dateRange[0]?.toISOString(),
            endDate: dateRange[1]?.toISOString(),
            employeeName: selectedEmployee
          },
        });


        // Initialize an array for 24 hours with empty data
        const fullDayData = Array.from({ length: 24 }, (_, hour) => ({
          time: `${hour}:00`,
        }));

        // Merge the backend data into the fullDayData, keeping all 24 hours
        response.data.forEach((item: DataItem) => {
          const hourIndex = fullDayData.findIndex((d) => d.time === item.time);
          if (hourIndex !== -1) {
            Object.assign(fullDayData[hourIndex], item); // Merge zone data into the respective hour
          }
        });


        setData(fullDayData); // Set the full day data, including empty hours
      } catch (error) {
        console.error("Error fetching chart data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dateRange, selectedEmployee]);

  // Get a list of all CP keys in the data
  const getCPKeys = () => {
    const cpKeys: Set<string> = new Set(); // Use a Set to avoid duplicates
    data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (key.startsWith("CP")) {
          cpKeys.add(key);
        }
      });
    });
    return Array.from(cpKeys); // Convert the Set back to an Array
  };

  const cpKeys = getCPKeys();

  const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#00C49F'];

  return (
    <Paper elevation={3} sx={{ padding: 3, marginTop: 3 }}>
      <Typography variant="h6" fontWeight="bold" fontSize={"15px"} gutterBottom>
        Time in Zones Today (minutes)
      </Typography>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", padding: "20px" }}>
          <CircularProgress />
        </Box>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis />
            <Tooltip />
            {/* <Legend /> */}
            {/* Dynamically add Bar components for each zone starting with "CP" */}
            {cpKeys.map((zone, index) => (
              <Bar key={zone} dataKey={zone} stackId="a" fill={colors[index % colors.length]} />
            ))}
          </BarChart>
        </ResponsiveContainer>
      )}
    </Paper>
  );
};

export default ChartOne;
